<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.arenas.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ pagination.total }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer></v-spacer>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1000px"
                        >
<!--                            <template v-slot:activator="{ on }">-->
<!--                                <v-btn-->
<!--                                    v-on="on"-->
<!--                                    color="primary"-->
<!--                                    class="mb-2"-->
<!--                                    dark-->
<!--                                >-->
<!--                                    {{ $t('miscellaneous.add') }}-->
<!--                                </v-btn>-->
<!--                            </template>-->
                            <v-card v-if="editDialog">
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="px-4 pb-4">
                                            <RequiredFields class="mb-4"/>
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <DialogImage
                                                    :image="editedEntity.image"
                                                    :name="editedEntity.name"
                                                />
                                                <v-row align="center">
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-flex>
                                                            <ImageInput
                                                                :file.sync="file"
                                                                :alreadyHasImage="editedEntity.image"
                                                            />
                                                        </v-flex>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.name"
                                                            @change="v => (editedEntity.name = v)"
                                                            :rules="[required]"
                                                            :label="$t('generalAttributes.name')"
                                                            prepend-icon="mdi-format-title"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-select
                                                            :value="editedEntity.company.id"
                                                            @change="editedEntity.company = $event"
                                                            :items="companies"
                                                            :rules="[v => !!v || $t('validation.companyIsRequired')]"
                                                            :menu-props="{ bottom: true, offsetY: true }"
                                                            :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                            item-text="name"
                                                            item-value="id"
                                                            class="do-not-apply-transform"
                                                            prepend-inner-icon="category"
                                                            return-object
                                                            solo
                                                        >
                                                            <template v-slot:label>
                                                                {{ $t('entities.arenas.selectCompany') }}
                                                                <span :style="{ color: 'red' }">*</span>
                                                            </template>
                                                            <template v-slot:selection="data">
                                                                {{ $t('entities.companies.entity') }}: {{ data.item.name }}
                                                            </template>
                                                        </v-select>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.timezone"
                                                            @change="v => (editedEntity.timezone = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.countries.attributes.timezone')"
                                                            prepend-icon="today"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.currency"
                                                            @change="v => (editedEntity.currency = v)"
                                                            :rules="[required]"
                                                            :label="$t('entities.countries.attributes.currency')"
                                                            prepend-icon="payments"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.coins_per_currency"
                                                            @change="v => (editedEntity.coins_per_currency = v)"
                                                            :rules="[required, integer, min]"
                                                            :label="$t('entities.countries.attributes.coinsPerCurrency')"
                                                            type="number"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    >
                                                        <v-text-field
                                                            :value="editedEntity.locale"
                                                            @change="v => (editedEntity.locale = v)"
                                                            :rules="[required, exactTwoLetters]"
                                                            :label="$t('entities.countries.attributes.locale')"
                                                            prepend-icon="language"
                                                        ></v-text-field>
                                                        <span class="required-fields">*</span>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="position-relative py-0"
                                                    />
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.active"
                                                            @change="v => (editedEntity.active = v)"
                                                            :label="$t('entities.arenas.attributes.active')"
                                                            prepend-icon="done"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        sm="6"
                                                        class="py-0"
                                                    >
                                                        <v-switch
                                                            :input-value="editedEntity.web_shop"
                                                            @change="v => (editedEntity.web_shop = v)"
                                                            :label="$t('entities.arenas.attributes.webShop')"
                                                            prepend-icon="shop"
                                                            inset
                                                        ></v-switch>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="py-6"
                                                    >
                                                    <EditCardActions
                                                        :save="save"
                                                        :saving="saving"
                                                        :closeEditDialog="closeEditDialog"
                                                    />
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.attributes.baseRate') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row align="center" class="mt-2 px-3">
                                                                        <v-col
                                                                            cols="12"
                                                                            class="px-6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.base_coin_rate"
                                                                                @change="v => (editedEntity.base_coin_rate = v)"
                                                                                :rules="[integer, min]"
                                                                                type="number"
                                                                            >
                                                                                <template v-slot:prepend>
                                                                                    <CoinImage/>
                                                                                </template>
                                                                            </v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                @click="saveBaseRate"
                                                                                :disabled="savingBaseRate"
                                                                                :loading="savingBaseRate"
                                                                            >
                                                                                {{ $t('entities.arenas.saveBaseRate') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.paymentMethods') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border py-2">
                                                                    <v-row
                                                                        v-for="(paymentMethod, key) in paymentMethods"
                                                                        :key="'payment-method-' + key"
                                                                        class="px-3"
                                                                    >
                                                                        <template v-if="editedEntity.payment_methods[paymentMethod.id]">
                                                                            <v-col
                                                                                cols="6"
                                                                                class="py-0"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.payment_methods[paymentMethod.id].value"
                                                                                    :label="$t(`miscellaneous.paymentMethods.${titleToCamelCase(paymentMethod.name)}`)"
                                                                                    :prepend-icon="paymentMethodIcon(titleToCamelCase(paymentMethod.name))"
                                                                                    inset
                                                                                    readonly
                                                                                ></v-switch>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="6"
                                                                                class="py-0"
                                                                            >
                                                                                <v-switch
                                                                                    :input-value="editedEntity.payment_methods[paymentMethod.id].fiscalize"
                                                                                    :disabled="!editedEntity.payment_methods[paymentMethod.id].value"
                                                                                    :label="$t('entities.arenas.fiscalize')"
                                                                                    prepend-icon="input"
                                                                                    inset
                                                                                    readonly
                                                                                ></v-switch>
                                                                            </v-col>
                                                                        </template>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.employees') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="my-2 px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            class="text-center"
                                                                        >
                                                                            <v-autocomplete
                                                                                v-model="editedEntity.employees"
                                                                                :items="employees.entities"
                                                                                :loading="employees.loading"
                                                                                :search-input.sync="employees.search"
                                                                                :label="$t(`entities.arenas.chooseEmployees.${employees.entities.length > 0 ? 'title' : 'search'}`)"
                                                                                :no-data-text="$t(`entities.arenas.chooseEmployees.${employees.entities.length > 0 ? 'noEmployees' : 'typeInTheName'}`)"
                                                                                :dense="$vuetify.breakpoint.xsOnly"
                                                                                :disabled="employees.loading"
                                                                                item-text="username"
                                                                                item-value="id"
                                                                                return-object
                                                                                hide-selected
                                                                                hide-details
                                                                                multiple
                                                                                cle
                                                                                chips
                                                                                solo
                                                                            >
                                                                                <template v-slot:selection="data">
                                                                                    <v-chip
                                                                                        @click="data.select"
                                                                                        @click:close="removeEmployee(data.item)"
                                                                                        :input-value="data.selected"
                                                                                        v-bind="data.attrs"
                                                                                        close
                                                                                    >
                                                                                        {{ data.item.username }}
                                                                                    </v-chip>
                                                                                </template>
                                                                                <template v-slot:item="{ item }">
                                                                                    <v-list-item-content>
                                                                                        <v-list-item-title v-text="item.username"></v-list-item-title>
                                                                                    </v-list-item-content>
                                                                                </template>
                                                                            </v-autocomplete>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingEmployees"
                                                                                :loading="savingEmployees"
                                                                                @click="saveEmployees">
                                                                                {{ $t('entities.arenas.saveEmployees') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.cashRegisters.title') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row align="center" class="mt-2 px-3">
                                                                        <v-col
                                                                            v-for="(cashRegister, index) in editedEntity.cash_registers"
                                                                            :key="'cash-register-' + index"
                                                                            cols="auto"
                                                                            class="text-center"
                                                                        >
                                                                            <v-row align="center">
                                                                                <v-col cols="8" class="py-0">
                                                                                    <v-text-field
                                                                                        v-model.number="cashRegister.label"
                                                                                        :rules="[val => Number.isInteger(val) && val >= 0 || 'Must be a positive integer']"
                                                                                        label="Cash Register ID"
                                                                                        type="number"
                                                                                        prepend-icon="mdi-cash-register"
                                                                                        readonly
                                                                                    ></v-text-field>
                                                                                </v-col>
                                                                            </v-row>
                                                                        </v-col>
                                                                        <v-col v-if="!editedEntity.cash_registers.length" cols="12" class="text-center">
                                                                            <p class="mb-0 pl-4 pb-0">{{ $t('entities.arenas.cashRegisters.notFound') }}</p>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.equipment.title') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <template v-if="editedEntity.equipment.length">
                                                                        <v-row
                                                                            v-for="index in editedEntity.equipment.length"
                                                                            :key="'equipment-' + index"
                                                                            align="center"
                                                                            class="my-2 px-3"
                                                                        >
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="6"
                                                                                class="position-relative px-2 py-0"
                                                                            >
                                                                                <v-text-field
                                                                                    :value="editedEntity.equipment[index - 1].name"
                                                                                    @change="v => (editedEntity.equipment[index - 1].name = v)"
                                                                                    :rules="[required]"
                                                                                    :label="$t('generalAttributes.name')"
                                                                                    prepend-icon="mdi-desktop-classic"
                                                                                ></v-text-field>
                                                                                <span class="required-fields">*</span>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="5"
                                                                                class="position-relative py-0"
                                                                            >
                                                                                <v-flex>
                                                                                    <ImageInput
                                                                                        :file.sync="editedEntity.equipment[index - 1].file"
                                                                                        :alreadyHasImage="editedEntity.equipment[index - 1].image"
                                                                                    />
                                                                                </v-flex>
                                                                            </v-col>
                                                                            <v-col
                                                                                cols="12"
                                                                                sm="1"
                                                                                class="px-2"
                                                                            >
                                                                                <v-btn
                                                                                    @click="removeEquipment(index-1)"
                                                                                    color="error"
                                                                                    rounded
                                                                                >
                                                                                    X
                                                                                </v-btn>
                                                                            </v-col>
                                                                            <v-col cols="12">
                                                                                <tiptap-vuetify
                                                                                    v-model="editedEntity.equipment[index - 1].description"
                                                                                    :extensions="extensions"
                                                                                    :placeholder="$t('generalAttributes.description')"
                                                                                    :toolbar-attributes="{ color: $vuetify.theme.dark ? 'primary' : '' }"
                                                                                />
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                    <template v-else>
                                                                        <v-row class="mt-2">
                                                                            <v-col class="text-center mb-0">
                                                                                <p class="mb-0 pl-4 pb-0">{{ $t('entities.arenas.equipment.notFound') }}</p>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </template>
                                                                    <v-row class="mb-2">
                                                                        <v-col class="text-center">
                                                                            <v-btn
                                                                                @click="addEquipment"
                                                                                color="primary"
                                                                                class="px-12"
                                                                                rounded
                                                                            >
                                                                                {{ $t('entities.arenas.equipment.create') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingEquipment"
                                                                                :loading="savingEquipment"
                                                                                @click="saveEquipment">
                                                                                {{ $t('entities.arenas.saveEquipment') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.workingHours') }}
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-icon v-if="!workingHoursFetched" color="warning" class="ml-2" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                                                                            </template>
                                                                            <span>{{ $t('entities.arenas.notifications.workingHoursFetchFailed') }}</span>
                                                                        </v-tooltip>
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border pt-2">
                                                                    <v-row
                                                                        v-for="(item, index) in editedEntity.working_hours"
                                                                        :key="`day-${index}`"
                                                                        align="center"
                                                                        class="px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            class="pl-4 pb-0"
                                                                            :class="{ 'pt-0' : index !== 0 }"
                                                                        >
                                                                            <p class="font-weight-bold">{{ days[index] }}</p>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                            class="py-0"
                                                                        >
                                                                            <v-dialog
                                                                                v-model="editedEntity.working_hours[index].modals.start"
                                                                                :return-value.sync="editedEntity.working_hours[index].intervals[0]"
                                                                                ref="start_dialog"
                                                                                width="290px"
                                                                            >
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-text-field
                                                                                        v-model="editedEntity.working_hours[index].intervals[0]"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                        :label="$t('entities.arenas.opening')"
                                                                                        prepend-icon="mdi-clock-start"
                                                                                        readonly
                                                                                    ></v-text-field>
                                                                                </template>
                                                                                <v-time-picker
                                                                                    v-if="editedEntity.working_hours[index].modals.start"
                                                                                    v-model="editedEntity.working_hours[index].intervals[0]"
                                                                                    format="24hr"
                                                                                    full-width
                                                                                >
                                                                                    <v-spacer></v-spacer>
                                                                                    <v-btn
                                                                                        @click="editedEntity.working_hours[index].modals.start = false"
                                                                                        color="primary"
                                                                                        text
                                                                                    >
                                                                                        {{ $t('miscellaneous.cancel') }}
                                                                                    </v-btn>
                                                                                    <v-btn
                                                                                        @click="$refs.start_dialog[index].save(editedEntity.working_hours[index].intervals[0])"
                                                                                        color="primary"
                                                                                        text
                                                                                    >
                                                                                        {{ $t('miscellaneous.confirm') }}
                                                                                    </v-btn>
                                                                                </v-time-picker>
                                                                            </v-dialog>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                            class="py-0"
                                                                        >
                                                                            <v-dialog
                                                                                v-model="editedEntity.working_hours[index].modals.end"
                                                                                :return-value.sync="editedEntity.working_hours[index].intervals[1]"
                                                                                ref="end_dialog"
                                                                                width="290px"
                                                                            >
                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                    <v-text-field
                                                                                        v-model="editedEntity.working_hours[index].intervals[1]"
                                                                                        v-bind="attrs"
                                                                                        v-on="on"
                                                                                        :label="$t('entities.arenas.closing')"
                                                                                        prepend-icon="mdi-clock-end"
                                                                                        readonly
                                                                                    ></v-text-field>
                                                                                </template>
                                                                                <v-time-picker
                                                                                    v-if="editedEntity.working_hours[index].modals.end"
                                                                                    v-model="editedEntity.working_hours[index].intervals[1]"
                                                                                    format="24hr"
                                                                                    full-width
                                                                                >
                                                                                    <v-spacer></v-spacer>
                                                                                    <v-btn
                                                                                        @click="editedEntity.working_hours[index].modals.end = false"
                                                                                        color="primary"
                                                                                        text
                                                                                    >
                                                                                        {{ $t('miscellaneous.cancel') }}
                                                                                    </v-btn>
                                                                                    <v-btn
                                                                                        @click="$refs.end_dialog[index].save(editedEntity.working_hours[index].intervals[1])"
                                                                                        color="primary"
                                                                                        text
                                                                                    >
                                                                                        {{ $t('miscellaneous.confirm') }}
                                                                                    </v-btn>
                                                                                </v-time-picker>
                                                                            </v-dialog>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingWorkingHours"
                                                                                :loading="savingWorkingHours"
                                                                                @click="saveWorkingHours">
                                                                                {{ $t('entities.arenas.saveWorkingHours') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.contact.title') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="mt-2 px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.manager"
                                                                                @change="v => (editedEntity.contact.manager = v)"
                                                                                :label="$t('entities.arenas.contact.manager')"
                                                                                prepend-icon="person"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.phone"
                                                                                @change="v => (editedEntity.contact.phone = v)"
                                                                                :label="$t('generalAttributes.phone')"
                                                                                prepend-icon="phone"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.email"
                                                                                @change="v => (editedEntity.contact.email = v)"
                                                                                :label="$t('entities.users.attributes.email')"
                                                                                prepend-icon="email"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.discord"
                                                                                @change="v => (editedEntity.contact.discord = v)"
                                                                                label="Discord"
                                                                                prepend-icon="mdi-discord"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.instagram"
                                                                                @change="v => (editedEntity.contact.instagram = v)"
                                                                                label="Instagram"
                                                                                prepend-icon="mdi-instagram"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.contact.facebook"
                                                                                @change="v => (editedEntity.contact.facebook = v)"
                                                                                label="Facebook"
                                                                                prepend-icon="mdi-facebook"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingContact"
                                                                                :loading="savingContact"
                                                                                @click="saveContact">
                                                                                {{ $t('entities.arenas.saveContact') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.attributes.bonuses') }}
                                                                        <v-tooltip bottom>
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-icon v-if="!bonusesFetched" color="warning" class="ml-2" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                                                                            </template>
                                                                            <span>{{ $t('entities.arenas.notifications.bonusesFetchFailed') }}</span>
                                                                        </v-tooltip>
                                                                    </h4>
                                                                    </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-simple-table>
                                                                        <template v-slot:default>
                                                                            <thead>
                                                                                <tr>
                                                                                    <th class="text-left">
                                                                                        {{ $t('entities.arenas.hour') }}
                                                                                    </th>
                                                                                    <th
                                                                                        v-for="day in days"
                                                                                        :key="`day-${day}`"
                                                                                        class="text-left"
                                                                                    >
                                                                                        {{ day }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    v-for="i in 24"
                                                                                    :key="`hour-${i}`"
                                                                                >
                                                                                    <td>
                                                                                      {{ formattedTime(i-1) }}:00
                                                                                    </td>
                                                                                    <td
                                                                                        v-for="(day, index) in days"
                                                                                        :key="`day-${day}`"
                                                                                        class="text-left"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.bonuses[index][i - 1]"
                                                                                            @change="v => (editedEntity.bonuses[index][i - 1] = v)"
                                                                                            :rules="[integer, between]"
                                                                                            :style="`background-color: ${getColor(editedEntity.bonuses[index][i - 1])}`"
                                                                                            type="number"
                                                                                            class="rate-input pt-0"
                                                                                        >
                                                                                            <template v-slot:append>
                                                                                                <v-icon
                                                                                                    small
                                                                                                    class="mt-1"
                                                                                                >
                                                                                                    mdi-percent
                                                                                                </v-icon>
                                                                                            </template>
                                                                                        </v-text-field>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </template>
                                                                    </v-simple-table>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingRateBonuses"
                                                                                :loading="savingRateBonuses"
                                                                                @click="saveRateBonuses">
                                                                                {{ $t('entities.arenas.saveBonuses') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.invoiceSettings') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="my-2 px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            class="py-0"
                                                                        >
                                                                            <v-switch
                                                                                :input-value="editedEntity.invoice_settings.automatically_open_print_upon_invoice_creation"
                                                                                :label="$t('entities.arenas.attributes.automaticallyOpenPrintOnInvoiceCreation')"
                                                                                prepend-icon="print"
                                                                                inset
                                                                                readonly
                                                                            ></v-switch>
                                                                        </v-col>
                                                                        <v-col>
                                                                            <p class="mb-0">{{ $t('entities.arenas.attributes.invoiceHeader') }}</p>
                                                                            <v-divider></v-divider>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-card-text
                                                                                v-model="editedEntity.invoice_settings.invoice_header"
                                                                                :placeholder="$t('entities.arenas.attributes.invoiceHeader')"
                                                                                readonly
                                                                                v-html="editedEntity.invoice_settings.invoice_header"
                                                                            />
                                                                        </v-col>
                                                                        <v-col>
                                                                            <p class="mb-0">{{ $t('entities.arenas.attributes.invoiceFooter') }}</p>
                                                                            <v-divider></v-divider>
                                                                        </v-col>
                                                                        <v-col cols="12">
                                                                            <v-card-text
                                                                                v-model="editedEntity.invoice_settings.invoice_footer"
                                                                                :placeholder="$t('entities.arenas.attributes.invoiceFooter')"
                                                                                readonly
                                                                                v-html="editedEntity.invoice_settings.invoice_footer"
                                                                            />
                                                                            <v-divider></v-divider>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('generalAttributes.map') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="mt-2 px-3"
                                                                    >
                                                                        <v-col cols="12">
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.link"
                                                                                @change="v => (editedEntity.geo_map.link = v)"
                                                                                :label="$t('miscellaneous.link')"
                                                                                prepend-icon="link"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.latitude"
                                                                                @change="v => (editedEntity.geo_map.latitude = v)"
                                                                                :label="$t('generalAttributes.latitude')"
                                                                                prepend-icon="language"
                                                                                type="number"
                                                                                :rules="[v => !isNaN(v) || $t('validation.mustBeNumber')]"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.longitude"
                                                                                @change="v => (editedEntity.geo_map.longitude = v)"
                                                                                :label="$t('generalAttributes.longitude')"
                                                                                prepend-icon="language"
                                                                                type="number"
                                                                                :rules="[v => !isNaN(v) || $t('validation.mustBeNumber')]"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.geo_map.zoom"
                                                                                @change="v => (editedEntity.geo_map.zoom = v)"
                                                                                :label="$t('generalAttributes.zoom')"
                                                                                prepend-icon="zoom_in"
                                                                                type="number"
                                                                                :rules="[v => !isNaN(v) || $t('validation.mustBeNumber')]"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingGeoMap"
                                                                                :loading="savingGeoMap"
                                                                                @click="saveGeoMap">
                                                                                {{ $t('entities.arenas.saveGeoMap') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('generalAttributes.address') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="mt-2 px-3"
                                                                    >
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="5"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.address1"
                                                                                @change="v => (editedEntity.address.address1 = v)"
                                                                                :label="$t('generalAttributes.street')"
                                                                                prepend-icon="edit_road"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="5"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.address2"
                                                                                @change="v => (editedEntity.address.address2 = v)"
                                                                                :label="$t('generalAttributes.details')"
                                                                                prepend-icon="info"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="5"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.settlement"
                                                                                @change="v => (editedEntity.address.settlement = v)"
                                                                                :label="$t('generalAttributes.city')"
                                                                                prepend-icon="location_city"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.postcode"
                                                                                @change="v => (editedEntity.address.postcode = v)"
                                                                                :label="$t('generalAttributes.postalCode')"
                                                                                prepend-icon="local_post_office"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.country"
                                                                                @change="v => (editedEntity.address.country = v)"
                                                                                :label="$t('entities.countries.entity')"
                                                                                prepend-icon="flag"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="6"
                                                                        >
                                                                            <v-text-field
                                                                                :value="editedEntity.address.country_code"
                                                                                @change="v => (editedEntity.address.country_code = v)"
                                                                                :label="$t('entities.countries.attributes.code')"
                                                                                prepend-icon="code"
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-row class="mx-3 mb-3 mt-3">
                                                                        <v-col cols="12" class="text-right" >
                                                                            <v-btn
                                                                                color="primary"
                                                                                :disabled="savingAddress"
                                                                                :loading="savingAddress"
                                                                                @click="saveAddress">
                                                                                {{ $t('entities.arenas.saveAddress') }}
                                                                            </v-btn>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-expansion-panels
                                                            :value="editDialog"
                                                            flat
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="pr-4 grey-border">
                                                                    <h4 class="pl-4">
                                                                        {{ $t('entities.arenas.attributes.termsAndConditions') }}
                                                                    </h4>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="grey-border">
                                                                    <v-row
                                                                        align="center"
                                                                        class="my-2 px-3"
                                                                    >
                                                                        <v-col cols="12">
                                                                            <v-card-text
                                                                                v-model="editedEntity.terms_and_conditions"
                                                                                :placeholder="$t('entities.arenas.attributes.termsAndConditions')"
                                                                                readonly
                                                                                v-html="editedEntity.terms_and_conditions"
                                                                            />
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.arenas.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <DialogImage
                                            :image="toDeleteEntity.image"
                                            :name="toDeleteEntity.name"
                                        />
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td>{{ $t('generalAttributes.name') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.name }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.arenas.attributes.baseRate') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ addThousandSeparator(toDeleteEntity.base_coin_rate) }}
                                                                <CoinImage/>
                                                            </td>
                                                            <td>{{ $t('entities.companies.entity') }}</td>
                                                            <td class="font-weight-bold">
                                                                {{ toDeleteEntity.company.name }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.arenas.attributes.active') }}</td>
                                                            <td>
                                                                <Boolean :boolean="toDeleteEntity.active"/>
                                                            </td>
                                                            <td>{{ $t('entities.arenas.attributes.webShop') }}</td>
                                                            <td class="font-weight-bold">
                                                                <Boolean :boolean="toDeleteEntity.web_shop"/>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <Boolean :boolean="item.active"/>
                </template>
                <template v-slot:item.base_coin_rate="{ item }">
                    {{ !isNaN(item.base_coin_rate) ? addThousandSeparator(item.base_coin_rate) : 'NaN'}}
                    <CoinImage/>
                </template>
                <template v-slot:item.action="{ item }">
                    <EditButton
                        :text="$t('entities.arenas.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.arenas.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import Boolean from '@/components/Boolean.vue';
import NoData from '@/components/crud/NoData.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import ImageInput from '@/components/ImageInput.vue';
import CoinImage from '@/components/CoinImage.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import DialogImage from '@/components/DialogImage';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import Snackbar from '@/components/Snackbar.vue';
import Arena from '@/models/setup/Arena';
import Company from '@/models/setup/Company';
import PaymentMethod from '@/models/misc/PaymentMethod';
import Warehouse from '@/models/misc/Warehouse';
import User from '@/models/users/User';
import FilesApi from '@/services/misc/FilesApi';
import {
    Blockquote,
    Bold,
    BulletList,
    Code,
    HardBreak,
    Heading,
    History,
    HorizontalRule,
    Italic,
    Link,
    ListItem,
    OrderedList,
    Paragraph,
    Strike,
    TiptapVuetify,
    Underline
} from 'tiptap-vuetify';

import required from '@/mixins/rules/required';
import thousandSeparator from '@/mixins/thousand-separator';
import convertCase from '@/mixins/convert-case';
import {axiosCatch} from '@/mixins/crud/methods/axios-catch.js';
import {showSnackbar} from "@/mixins/crud/methods/show-snackbar";
import {paginationWatch} from '@/mixins/crud/watch/pagination.js';
import {deleteDialog} from '@/mixins/crud/watch/delete-dialog.js';

export default Vue.extend({
    components: {
        Boolean,
        NoData,
        RefreshButton,
        ImageInput,
        CoinImage,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        EditToolbar,
        EditButton,
        EditCardActions,
        DeleteToolbar,
        DeleteButton,
        DeleteCardActions,
        DialogImage,
        RequiredFields,
        Snackbar,
        TiptapVuetify,
    },

    mixins: [
        axiosCatch,
        showSnackbar,
        paginationWatch,
        deleteDialog,
        thousandSeparator,
        convertCase,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        saving: false,
        savingBaseRate: false,
        savingRateBonuses: false,
        savingWorkingHours: false,
        savingEmployees: false,
        savingContact: false,
        savingGeoMap: false,
        savingEquipment: false,
        savingAddress: false,
        deleting: false,
        workingHoursFetched: false,
        employeesFetched: false,
        bonusesFetched: false,
        typing: false,
        entity: 'arenas',
        entities: [],
        companies: [],
        paymentMethods: [],
        employees: {
            entities: [],
            loading: false,
            search: null,
            debounce: null,
        },
        selectedSearchAttributes: ['Name'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ['created_at'],
            sortDesc: ['desc'],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        editDialog: false,
        deleteDialog: false,
        editedIndex: -1,
        file: null,
        image: null,
        defaultWorkingHours: [
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
            {
                intervals: [
                    null, null
                ],
                modals: {
                    start: false,
                    end: false
                }
            },
        ],
        defaultContact: {
            manager: null,
            phone: null,
            email: null,
            discord: null,
            instagram: null,
            facebook: null,
        },
        defaultBonuses: [
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ]
        ],
        defaultGeoMap: {
            link: '',
            latitude: null,
            longitude: null,
            zoom: null
        },
        defaultInvoiceSettings: {
            automatically_open_print_upon_invoice_creation: 0,
            invoice_header: '',
            invoice_footer: '',
        },
        defaultAddress: {
            address1: '',
            address2: null,
            settlement: '',
            postcode: null,
            country: '',
            country_code: ''
        },
        editedEntity: {
            id: null,
            company: {
                id: null,
                name: '',
            },
            image: '',
            name: '',
            timezone: '',
            coins_per_currency: null,
            currency: null,
            locale: null,
            active: 0,
            web_shop: 0,
            base_coin_rate: null,
            payment_methods: [],
            employees: [],
            cash_registers: [],
            warehouses: [],
            equipment: [],
            working_hours: null,
            contact: null,
            bonuses: null,
            geo_map: null,
            invoice_settings: null,
            address: null,
            terms_and_conditions: '',
        },
        defaultEntity: {
            id: null,
            company: {
                id: null,
                name: '',
            },
            image: '',
            name: '',
            timezone: '',
            coins_per_currency: null,
            currency: null,
            locale: null,
            active: 0,
            web_shop: 0,
            base_coin_rate: 0,
            payment_methods: [],
            employees: [],
            cash_registers: [],
            warehouses: [],
            equipment: [],
            working_hours: null,
            contact: null,
            bonuses: null,
            geo_map: null,
            invoice_settings: null,
            address: null,
            terms_and_conditions: '',
        },
        toDeleteEntity: {
            id: null,
            company: {
                id: null,
                name: '',
            },
            image: '',
            name: '',
            timezone: '',
            active: 0,
            web_shop: 0,
            base_coin_rate: 0,
            payment_methods: [],
            employees: [],
            cash_registers: [],
            warehouses: [],
            equipment: [],
            working_hours: null,
            contact: null,
            bonuses: null,
            geo_map: null,
            invoice_settings: null,
            address: null,
            terms_and_conditions: '',
        },
        extensions: [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3]
                }
            }],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ],
        toDeleteEquipment: []
    }),

    computed: {
        headers() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name' },
                { text: this.$t('entities.companies.entity'), align: 'left', value: 'company.name', sortable: false },
                { text: this.$t('entities.arenas.attributes.active'), align: 'left', value: 'active' },
                { text: this.$t('entities.arenas.attributes.baseRate'), align: 'left', value: 'base_coin_rate', sortable: false },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];
        },
        searchAttributes() {
            return [
                { label: this.$t('generalAttributes.name'), value: 'Name' },
            ]
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        integer() {
            return v => (!isNaN(v) && parseInt(Number(v)) == v && !isNaN(parseInt(v, 10))) || this.$t('validation.integer');
        },
        between() {
            return v => (v >= 0 && v <= 100) || this.$t('validation.between', { from: 0, to: 100 }); // TODO => Bonuses bad display
        },
        min() {
            return v => (v >= 0) || this.$t('validation.min', { amount: 0 });
        },
        minLabel() {
            return v => (v > 0) || this.$t('validation.min', { amount: 1 });
        },
        exactTwoLetters() {
            return v => /^[a-z]{2}$/.test(v) || this.$t('validation.exactly', { count: 2 });
        },
        days() {
            return {
                0: this.$t('miscellaneous.days.monday'),
                1: this.$t('miscellaneous.days.tuesday'),
                2: this.$t('miscellaneous.days.wednesday'),
                3: this.$t('miscellaneous.days.thursday'),
                4: this.$t('miscellaneous.days.friday'),
                5: this.$t('miscellaneous.days.saturday'),
                6: this.$t('miscellaneous.days.sunday')
            }
        },
        formattedTime() {
          return i => (i < 10 ? `0${i}` : i);
        },
    },

    watch: {
        editDialog(val) {
            val || this.closeEditDialog();
            if (val) {
                if (this.editedIndex === -1) {
                    this.editedEntity = this.assignNonExistingArenaAttributes(this.editedEntity);
                    // TODO => Refactor
                    const paymentMethods = {};
                    for (const i in this.paymentMethods) {
                        paymentMethods[this.paymentMethods[i].id] = {
                            value: false,
                            fiscalize: true,
                        };
                    }
                    this.editedEntity.payment_methods = paymentMethods;
                }
            } else {
                this.$refs.form.resetValidation();
                this.validationErrors = [];
            }
        },
        'employees.search'() {
            if (this.employees.search) {
                if (this.employees.debounce) {
                    clearTimeout(this.employees.debounce);
                }
                this.employees.debounce = setTimeout(() => {
                    this.fetchEmployees(this.employees.search);
                }, 1000)
            }
        },
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities() {
            try {
                this.loading = true;
                const append = this.setParams();
                const response = await Arena.api.get(append+"&include_fields=company");
                const data = response.data;

                this.entities = await Promise.all(data.data.map(async (ArenaDTO) => {
                    const arena = new Arena(ArenaDTO);
                    try {
                        const response = await Arena.api.getBaseRate(arena.id);
                        arena.base_coin_rate = response.data.base_rate;
                    } catch (e) {
                        console.warn('Error fetching base rate.');
                        console.log(e);
                    }

                    return this.assignNonExistingArenaAttributes(arena);
                }));
                this.pagination.current_page = data.metadata.page;
                this.pagination.per_page = data.metadata.size;
                this.pagination.total = data.metadata.count;
                this.pagination.last_page = Math.ceil(data.metadata.count / data.metadata.size);
                this.pagination.from = (data.metadata.page-1)*data.metadata.size + 1;
                this.pagination.to = this.pagination.from + data.metadata.size - 1 > this.pagination.total ? this.pagination.total : this.pagination.from + data.metadata.size - 1;
            } catch (e) {
                console.warn('Arenas API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchEntity(arenaId) {
            try {
                const response = await Arena.api.read(arenaId,"include_fields=company&include_fields=invoice_settings&include_fields=payment_methods&include_fields=cash_registers&include_fields=equipment&include_fields=contact&include_fields=geo_map&include_fields=address&include_fields=company");
                const data = response.data;

                this.editedEntity = JSON.parse(JSON.stringify(data));

                try {
                    const response = await Arena.api.getBaseRate(this.editedEntity.id);
                    this.editedEntity.base_coin_rate = response.data.base_rate;
                } catch (e) {
                    console.warn('Error fetching base rate.');
                    console.log(e);
                }

                try {
                    const response = await Arena.api.getTermsAndConditions(this.editedEntity.id);
                    this.editedEntity.terms_and_conditions = response.data.terms_and_conditions;
                } catch (e) {
                    console.warn('Error fetching terms and conditions.');
                    console.log(e);
                }

                this.assignNonExistingArenaAttributes(this.editedEntity);
            } catch (e) {
                console.warn('Arenas API failed.');
                console.log(e);
            }
        },
        async fetchCompanies() {
            try {
                this.loading = true;
                const response = await Company.api.get('size=100');
                this.companies = response.data.data.map(CompanyDTO => new Company(CompanyDTO));
            } catch (e) {
                console.warn('Companies API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchPaymentMethods() {
            try {
                this.loading = true;
                const response = await PaymentMethod.api.get();
                this.paymentMethods = response.data.map(PaymentMethodDTO => new PaymentMethod(PaymentMethodDTO));
            } catch (e) {
                console.warn('Payment Methods API failed.');
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async fetchWarehouses(arenaId) {
            try {
                const response = await Warehouse.api.get(0, `&arena_id=${arenaId}`);
                const warehouses = response.data.map(WarehouseDTO => new Warehouse(WarehouseDTO));
                this.$set(this.editedEntity, 'warehouses', warehouses);
            } catch (e) {
                console.warn('Warehouse API failed.');
                console.log(e);
            }
        },
        async fetchRateBonus(arenaId) {
            try {
                const response = await Arena.api.getRateBonuses(arenaId);
                const rateBonus= this.getMappedRateBonus(response.data);
                this.$set(this.editedEntity, 'bonuses', rateBonus);
                this.bonusesFetched = true;
            } catch (e) {
                console.warn('Error fetching arena rate bonus.');
                console.log(e);
                this.showSnackbar('orange', this.$t('entities.arenas.notifications.rateBonusFetchFailed'));
                this.bonusesFetched = false;
            }
        },
        async fetchWorkingHours(arenaId) {
            try {
                const response = await Arena.api.getWorkingHours(arenaId);
                const workingHours = this.getMappedWorkingHours(response.data);
                this.$set(this.editedEntity, 'working_hours', workingHours);
                this.workingHoursFetched = true;
            } catch (e) {
                console.warn('Error fetching arena rate bonus.');
                console.log(e);
                const workingHours = this.getMappedWorkingHours(null);
                this.$set(this.editedEntity, 'working_hours', workingHours);
                this.showSnackbar('orange', this.$t('entities.arenas.notifications.workingHoursFetchFailed'));
                this.workingHoursFetched = false;
            }
        },
        async fetchArenaEmployees(arenaId) {
            try {
                const response = await Arena.api.getEmployees(arenaId);
                const employees = response.data;
                this.$set(this.editedEntity, 'employees', employees);
                this.employeesFetched = true;
            } catch (e) {
                console.warn('Error fetching arena employees.');
                console.log(e);
                this.showSnackbar('orange', this.$t('entities.arenas.notifications.employeesFetchFailed'));
                this.employeesFetched = false;
            }
        },
        async fetchEmployees(username) {

            let page = 1;
            const pageSize = 100;
            let totalPages = 1;

            try {
                this.employees.loading = true;
                do {
                    this.employees.loading = true;
                    let query = `employees=true&username=${username}&size=${pageSize}&page=${page}`;
                    if (username === "") {
                       query = `employees=true&size=${pageSize}&page=${page}`
                    }
                    const response = await User.api.get(query);
                    response.data.content.forEach((UserDTO) => {
                        const existingUser = this.employees.entities.find(user => user.id === UserDTO.id);
                        if (!existingUser) {
                            this.employees.entities.push({
                                id: UserDTO.id,
                                username: UserDTO.username,
                            });
                        }
                    });
                    totalPages = response.data.total_pages;
                    page++;
                } while (page <= totalPages);
            } catch (e) {
                console.warn('Employees API failed.');
                console.log(e);
            } finally {
                this.employees.loading = false;
            }
        },
        async getUser(id) {
            const response = await User.api.find(id);
            return response.data;
        },
        async save() {
            try {
                this.saving = true;

                if (!this.$refs.form.validate()) {
                    return;
                }

                if (this.file) {
                    await FilesApi.upload('arenas', this.file)
                        .then((response) => {
                            this.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            this.image = response.data.link;
                        })
                        .catch(() => {
                            this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                        });
                }

                const data = {
                    name: this.editedEntity.name,
                    company_id: this.editedEntity.company.id,
                    active: this.editedEntity.active ? 1 : 0,
                    web_shop: this.editedEntity.web_shop ? 1 : 0,
                    timezone: this.editedEntity.timezone,
                    currency: this.editedEntity.currency,
                    coins_per_currency: this.editedEntity.coins_per_currency,
                    locale: this.editedEntity.locale,
                    geo_map: this.editedEntity.geo_map,
                    image: this.image || this.editedEntity.image,
                };

                if (this.editedIndex > -1) {
                    await Arena.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.$store.getters.arena && this.$store.getters.arena.id === this.editedEntity.id) {
                                const arena = {
                                    id: this.editedEntity.id,
                                    name: this.editedEntity.name,
                                    timezone: this.editedEntity.timezone,
                                    cashRegisters: this.editedEntity.cash_registers,
                                    paymentMethods: this.paymentMethods.filter(pm => this.editedEntity.payment_methods[pm.id].value),
                                    company: {
                                        taxes: this.editedEntity.company.taxes,
                                    },
                                    currency: this.editedEntity.currency,
                                    locale: this.editedEntity.locale,
                                    coins_per_currency: this.editedEntity.coins_per_currency,
                                    automaticallyOpenPrintUponInvoiceCreation: this.editedEntity.invoice_settings.automatically_open_print_upon_invoice_creation,
                                };

                                this.$store.commit('setSelectedCashRegisterLabel', null);
                                this.$store.commit('setArena', arena);
                            }
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.showSnackbar('success', this.$t('entities.arenas.notifications.update'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                            this.showSnackbar('error', this.$t('entities.arenas.notifications.updateFailed'));
                        });
                } else {
                    await Arena.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.arenas.notifications.creation'));
                        })
                }
            } catch (error) {
                this.axiosCatch(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.updateFailed'));
                console.warn(`Arena Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async saveBaseRate(){
            try {
                this.savingBaseRate = true
                const arenaId = this.editedEntity.id
                const payload = {
                        base_rate: parseInt(this.editedEntity.base_coin_rate)
                    }
                await Arena.api.updateBaseRate(arenaId, payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.rateBonusUpdated'));
                        this.fetchEntities();
                    });
            } catch (error) {
                console.warn(`Saving Arena rate bonus failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.rateBonusUpdateFailed'));
            } finally {
                this.savingBaseRate = false;
            }
        },
        async saveEmployees() {
            try {
                this.savingEmployees = true
                const employees = this.editedEntity.employees.map(employee => employee.id)
                const arenaId = this.editedEntity.id
                await Arena.api.updateEmployees(arenaId, employees)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.employeesUpdated'));
                    });
            } catch (error) {
                console.warn(`Saving Arena employees failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.employeesUpdateFailed'));
            } finally {
                this.savingEmployees = false;
            }
        },
        async saveAddress(){
            try {
                this.savingAddress = true
                const addressId = this.editedEntity.address.id
                const payload = {
                    address1: this.editedEntity.address.address1,
                    address2: this.editedEntity.address.address2 || null,
                    postcode: this.editedEntity.address.postcode || null,
                    settlement: this.editedEntity.address.settlement,
                    country: this.editedEntity.address.country,
                    country_code: this.editedEntity.address.country_code,
                }
                if (!addressId) {

                    payload.arena_id = this.editedEntity.id

                    await Arena.api.createAddress(payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.addressCreated'));
                    });
                } else {
                    await Arena.api.updateAddress(addressId, payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.addressUpdated'));
                    });
                }
            } catch (error) {
                console.warn(`Saving Arena address failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.addressUpdateFailed'));
            } finally {
                this.savingAddress = false;
            }
        },
        async saveGeoMap() {
            try {
                this.savingGeoMap = true
                const mapId = this.editedEntity.geo_map.id
                const payload = {
                        link: this.editedEntity.geo_map.link || null,
                        latitude: parseFloat(this.editedEntity.geo_map.latitude),
                        longitude: parseFloat(this.editedEntity.geo_map.longitude),
                        zoom: parseFloat(this.editedEntity.geo_map.zoom)
                    }
                if (!mapId) {
                    payload.arena_id = this.editedEntity.id
                    await Arena.api.createGeoMap(payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.geoMapCreated'));
                    });
                } else {
                    await Arena.api.updateGeoMap(mapId, payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.geoMapUpdated'));
                    });
                }
            } catch (error) {
                console.warn(`Saving Arena map failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.geoMapUpdateFailed'));
            } finally {
                this.savingGeoMap = false;
            }
        },
        async saveContact() {
            try {
                this.savingContact = true
                const contactId = this.editedEntity.contact.id
                const payload = {
                    manager: this.editedEntity.contact.manager || null,
                    phone: this.editedEntity.contact.phone || null,
                    email: this.editedEntity.contact.email,
                    discord: this.editedEntity.contact.discord || null,
                    instagram: this.editedEntity.contact.instagram || null,
                    facebook: this.editedEntity.contact.facebook || null,
                }
                if (!contactId) {
                    payload.arena_id = this.editedEntity.id
                    await Arena.api.createContact(payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.contactCreated'));
                    });
                } else {
                    await Arena.api.updateContact(contactId, payload)
                    .then(() => {
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.contactUpdated'));
                    });
                }
            } catch (error) {
                console.warn(`Saving Arena contact failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.contactUpdateFailed'));
            } finally {
                this.savingContact = false;
            }
        },
        async saveEquipment() {
            try {
                this.savingEquipment = true

                for (const equipment of this.editedEntity.equipment) {
                    const payload = {
                            name: equipment.name,
                            description: equipment.description,
                        }

                    if (equipment.file) {
                        await FilesApi.upload('arenas', equipment.file)
                        .then((response) => {
                            equipment.file = null;
                            if (!response || response.status !== 200) {
                                this.showSnackbar('error', this.$t('miscellaneous.fileUploadFailed'));
                                return;
                            }
                            payload.image = response.data.link;
                        });
                    }

                    if (equipment.id) {
                        await Arena.api.updateEquipment(equipment.id, payload);
                    } else {
                        payload.arena_id = this.editedEntity.id
                        await Arena.api.createEquipment(payload);
                    }
                }
                for (const equipment of this.toDeleteEquipment) {
                    await Arena.api.deleteEquipment(equipment.id);
                }
                this.showSnackbar('success', this.$t('entities.arenas.notifications.equipmentSaved'));
            } catch (error) {
                console.warn(`Saving Arena equipment failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.equipmentSaveFailed'));
            } finally {
                this.savingEquipment = false;
            }
        },
        async saveRateBonuses() {
            try{
                this.savingRateBonuses = true
                const bonuses = [];
            for (const i in this.editedEntity.bonuses) {
                for (const j in this.editedEntity.bonuses[i]) {
                    bonuses.push({
                        day: parseInt(i) + 1,
                        hour: parseInt(j),
                        value: parseInt(this.editedEntity.bonuses[i][j])
                    })
                }
            }
            const payload = {
                bonuses: bonuses
            }
            const arenaId = this.editedEntity.id
            await Arena.api.updateRateBonuses(arenaId, payload)
                .then(() => {
                    this.showSnackbar('success', this.$t('entities.arenas.notifications.bonusesUpdated'));
                });
            } catch (error) {
                console.warn(`Saving Arena rate bonuses failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.bonusesUpdateFailed'));
            } finally {
                this.savingRateBonuses = false;
            }
        },
        async saveWorkingHours() {
            try{
                this.savingWorkingHours = true
                const workingHours = [];
                for (const value of Object.values(this.editedEntity.working_hours)) {
                    workingHours.push(value.intervals);
                }

            const payload = {
                working_hours: workingHours
            }

            const arenaId = this.editedEntity.id
            await Arena.api.updateWorkingHours(arenaId, payload)
                .then(() => {
                    this.showSnackbar('success', this.$t('entities.arenas.notifications.workingHoursUpdated'));
                });
            } catch (error) {
                console.warn(`Saving Arena working hours failed.`);
                console.log(error);
                this.showSnackbar('error', this.$t('entities.arenas.notifications.workingHoursUpdateFailed'));
            } finally {
                this.savingWorkingHours = false;
            }
        },
        async deleteEntity() {
            try {
                this.deleting = true;
                await Arena.api.delete(this.toDeleteEntity.id)
                    .then(() => {
                        this.fetchEntities();
                        this.showSnackbar('success', this.$t('entities.arenas.notifications.deletion'));
                        if (this.$store.getters.arena && this.$store.getters.arena.id === this.toDeleteEntity.id) {
                            this.$store.commit('setArena', null);
                            this.$store.commit('setSelectedCashRegisterLabel', null);
                            this.$router.push('/arenas');
                            // TODO => Remove selected arena from search & list
                        }
                        this.closeDeleteDialog();
                        // TODO => Delete arena warehouses
                    }).catch(error => {
                        this.axiosCatch(error);
                    });
            } catch (error) {
                console.warn(`Arena Delete API failed.`);
                console.log(error);
            } finally {
                this.deleting = false;
            }
        },

        loadCashRegisterData() {
            const maxId = this.editedEntity.cash_registers.reduce((max, cr) => cr.id > max ? cr.id : max, 0);
            this.nextCashRegisterId = maxId + 1;
        },
        addEquipment() {
            this.editedEntity.equipment.push({ name: '', description: '', image: '', file: null })
        },
        removeEquipment(index) {
            if (this.editedEntity.equipment[index] && this.editedEntity.equipment[index].id) {
                this.toDeleteEquipment.push(this.editedEntity.equipment[index]);
            }
            this.editedEntity.equipment.splice(index, 1);
        },
        async openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            await this.fetchEntity(entity.id);
            await Promise.all([
                this.fetchWarehouses(entity.id),
                this.fetchRateBonus(entity.id),
                this.fetchWorkingHours(entity.id),
                this.fetchArenaEmployees(entity.id)
            ]);

            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            await this.mapEmployees();

            // TODO => Refactor
            if (Array.isArray(this.editedEntity.payment_methods)) {
                const paymentMethods = {};
                for (const i in this.paymentMethods) {
                    // Set payment methods
                    if (!(this.paymentMethods[i].id in paymentMethods)) {
                        paymentMethods[this.paymentMethods[i].id] = {
                            value: false,
                            fiscalize: false,
                        };
                    }
                    // If arena has payment method, set it's values
                    this.editedEntity.payment_methods.filter(pm => {
                        if (pm.payment_method.id === this.paymentMethods[i].id) {
                            paymentMethods[this.paymentMethods[i].id] = {
                                value: true,
                                fiscalize: pm.fiscalize,
                            };
                        }
                    });

                }
                this.$set(this.editedEntity, 'payment_methods', paymentMethods);
            }

            await this.$nextTick();
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.image = null;
            this.editDialog = false;
            this.toDeleteEquipment = [];
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        paymentMethodIcon(paymentMethod) {
            if (paymentMethod === 'cash') {
                return 'mdi-cash';
            } else if (paymentMethod === 'creditCard') {
                return 'credit_card';
            } else if (paymentMethod === 'bank') {
                return 'mdi-bank';
            } else {
                return 'input';
            }
        },
        assignNonExistingArenaAttributes(arena) {
            if (!arena.geo_map) {
                arena.geo_map = JSON.parse(JSON.stringify(this.defaultGeoMap));
            }

            if (!arena.contact) {
                arena.contact = JSON.parse(JSON.stringify(this.defaultContact));
            }

            if (!arena.address) {
                arena.address = JSON.parse(JSON.stringify(this.defaultAddress));
            }

            if (!arena.bonuses || !arena.bonuses.length) {
                arena.bonuses = JSON.parse(JSON.stringify(this.defaultBonuses));
            }

            if (!arena.invoice_settings) {
                arena.invoice_settings = JSON.parse(JSON.stringify(this.defaultInvoiceSettings));
            }

            arena.warehouses = [];

            return arena;
        },
        getMappedRateBonus(rateBonus){
            if (!rateBonus || !rateBonus.length) {
                return JSON.parse(JSON.stringify(this.defaultBonuses));
            } else {
                return rateBonus;
            }
        },
        getMappedWorkingHours(workingHours){
            if (!workingHours) {
                return JSON.parse(JSON.stringify(this.defaultWorkingHours));
            } else {
                const workingHoursList = [];
                for (const wh in workingHours) {
                    workingHoursList.push({
                        intervals: workingHours[wh],
                        modals: {
                            start: false,
                            end: false
                        }
                    });
                }
                return workingHoursList
            }
        },
        getColor(value) {
            const hue = (value / 100 * 120).toString(10);
            return `hsl(${hue}, 100%, 50%)`;
        },
        removeEmployee(employee) {
            this.editedEntity.employees.splice(this.editedEntity.employees.findIndex(emp => emp.username == employee.username), 1);
        },
        refresh() {
            this.fetchEntities();
            this.fetchCompanies();
            this.fetchPaymentMethods();
            this.loadCashRegisterData();
        },

        async mapEmployees(){
            for (const [idx, employeeId] of this.editedEntity.employees.entries()){
                let user = this.employees.entities.find(user => user.id === employeeId);
                if (!user) {
                    const resp = await this.getUser(employeeId);
                    user = {
                        id: resp.id,
                        username: resp.username,
                    };
                    this.employees.entities.push(user);
                }
                this.editedEntity.employees[idx] = user;
            }
        }

    }
});

</script>


<style lang="scss">

.position-relative {
    position: relative;
}

.required-fields {
    position: absolute;
    right: 13px;
    top: 15px;
    color: red;
}

.v-select .v-input__slot {
    margin-bottom: 0;
}

.v-application .v-select-list .v-divider {
    margin-top: 0 !important;
}

.rate-input {
    width: 50px !important;
    height: 35px;
    margin-top: 0;
    padding-top: 0;
    input {
        text-align: center;
        padding-left: 10px;
        padding-top: 10px;
    }
}

</style>
