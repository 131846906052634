import convertCase from "@/mixins/convert-case";

export const paginationWatch = {

    data: () => ({
        debounce: null,
    }),

    watch: {
        'pagination.per_page': function() {
            if (this.onFirstPage()) {
                this.fetchEntities(this.setParams());
            } else {
                this.setCurrentPageToOne();
            }
        },
        'pagination.search': function(newVal) {
            this.typing = true;
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            if (newVal.includes('FFCARDID:')) {
                this.pagination.search = newVal.replace('FFCARDID:', '').trim();
            }
            this.debounce = setTimeout(() => {
                if (this.onFirstPage()) {
                    this.fetchEntities(this.setParams());
                } else {
                    this.setCurrentPageToOne();
                }
                this.typing = false;
            }, 1500);
        },
        'pagination.current_page': function() {
            this.fetchEntities(this.setParams());
        },
        'options.sortBy': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                this.sort()
            }
        },
    },

    methods: {
        formatSelectedSearchAttributes() {
            if (!this.pagination.search.length) {
                return '';
            }

            const searchAttributes = this.selectedSearchAttributes.map(attribute => `${attribute.replaceAll(' ', '_').toLowerCase()}=${this.pagination.search}`);
            return '&' + searchAttributes.join('&');
        },
        setParams() {
            return `&size=${this.pagination.per_page}&page=${this.pagination.current_page}${this.formatSelectedSearchAttributes()}${this.getSorting()}`;
        },
        getSorting() {
            if (this.options.sortBy && this.options.sortBy.length) {
                let sortBy = this.options.sortBy[0];
                // Invoices, carts, orders (shop)
                if (this.options.sortBy[0] !== 'created_at' && this.options.sortBy[0] !== 'expires_at') {
                    sortBy = convertCase.methods.snakeToCamelCase(this.options.sortBy[0]);
                }
                const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                return `&sort=${sortBy},${sortDirection}`;
            } else {
                return '';
            }
        },
        setCurrentPageToOne() {
            this.pagination.current_page = 1;
        },
        onFirstPage() {
            return this.pagination.current_page === 1;
        },
        sort() {
            if (!this.options.sortBy.length) {
                this.fetchEntities(this.setParams());
            } else {
                if (this.onFirstPage()) {
                    this.fetchEntities(this.setParams());
                } else {
                    this.setCurrentPageToOne();
                }
            }
        }
    }

};
