<template>
    <div>
        <v-card class="elevation-2">
            <DataTableHeader
                :pagination="pagination"
                :searchAttributes="searchAttributes"
                :selectedSearchAttributes.sync="selectedSearchAttributes"
                :disabled="loading"
            />
            <DataTableSkeletonLoader :loading="initialLoad"/>
            <v-data-table
                v-show="!initialLoad"
                :headers="headers"
                :items="entities"
                :dense="$vuetify.breakpoint.xs && !loading"
                :loading="loading"
                :loading-text="$t('components.noData.fetchingEntries')"
                :options.sync="options"
                :server-items-length="pagination.total"
                :items-per-page="pagination.per_page"
                :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                :class="{ 'mobile-empty-datatable' : $vuetify.breakpoint.xsOnly }"
                hide-default-footer
            >
                <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>{{ $t('entities.orders.title') }}</v-toolbar-title>
                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        ></v-divider>
                        <small class="mr-2">{{ addThousandSeparator(pagination.total) }}</small>
                        <RefreshButton
                            :refresh="refresh"
                            :loading="loading"
                        />
                        <v-spacer/>
                        <v-dialog
                            v-model="editDialog"
                            max-width="1200px"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    color="primary"
                                    class="mb-2"
                                    dark
                                >
                                    {{ $t('miscellaneous.add') }}
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <EditToolbar
                                        :formTitle="formTitle"
                                        :closeEditDialog="closeEditDialog"
                                    />
                                    <v-container>
                                        <v-card class="mt-4 px-4 pb-4">
                                            <v-form
                                                @submit.prevent="save"
                                                method="POST"
                                                ref="form"
                                            >
                                                <v-row
                                                    v-if="editDialog"
                                                    align="center"
                                                >
                                                    <RequiredFields/>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6 pt-4 pb-0"
                                                    >
                                                        <v-card
                                                            v-if="editedIndex > -1"
                                                            class="px-6 py-2"
                                                        >
                                                            <template v-if="editedEntity.user">
                                                                <span>{{ $t('miscellaneous.user') }}</span>
                                                                <br>
                                                                <span>{{ $t('generalAttributes.name') }}: <b>{{ editedEntity.user.name || '/' }}</b></span>
                                                                <br>
                                                                <span>{{ $t('entities.users.attributes.username') }}: <b>{{ editedEntity.user.username }}</b></span>
                                                                <br>
                                                                <span>{{ $t('entities.users.attributes.email') }}: <b>{{ editedEntity.user.email ? editedEntity.user.email.address : '/' }}</b></span>
                                                            </template>
                                                            <template v-else>
                                                                <span>
                                                                    {{ $t('entities.orders.noUser') }}
                                                                </span>
                                                            </template>
                                                        </v-card>
                                                        <v-expansion-panels class="mt-4">
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="px-6">
                                                                    <template v-if="editedIndex > -1 && editedEntity.user">
                                                                        {{ $t('entities.orders.changeUser') }}
                                                                    </template>
                                                                    <template v-else>
                                                                        <template v-if="coinsOrPassAddedAndUserNotSelected">
                                                                            <i class="red--text text--lighten-1">
                                                                                {{ $t('entities.orders.attachUserToOrderRequired') }}
                                                                            </i>
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t('entities.orders.attachUserToOrder') }}
                                                                        </template>
                                                                    </template>
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="px-6">
                                                                    <v-row>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                            class="text-center py-0"
                                                                        >
                                                                            <RowsPerPage :pagination="usersPagination"/>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                            class="text-center py-0"
                                                                        >
                                                                            <v-text-field
                                                                                v-model="usersPagination.search"
                                                                                @click:append="() => (usersPagination.search = '')"
                                                                                @keydown.native.escape="usersPagination.search = ''"
                                                                                :label="userSelectedSearchAttributes.length ? $t('components.dataTableHeader.search') : $t('components.dataTableHeader.searchDisabled')"
                                                                                :disabled="!userSelectedSearchAttributes.length"
                                                                                :append-icon="usersPagination.search ? 'close': ''"
                                                                                prepend-inner-icon="search"
                                                                                class="search mb-1"
                                                                                hide-details
                                                                                solo
                                                                            ></v-text-field>
                                                                        </v-col>
                                                                        <v-col
                                                                            cols="12"
                                                                            sm="4"
                                                                            class="text-center py-0"
                                                                        >
                                                                            <v-select
                                                                                @change="userSelectedSearchAttributes = $event"
                                                                                :items="userSearchAttributes"
                                                                                :value="userSelectedSearchAttributes"
                                                                                :label="$t('components.dataTableHeader.searchBy')"
                                                                                :menu-props="{ bottom: true, offsetY: true }"
                                                                                :no-data-text="$t('components.dataTableHeader.noDataAvailable')"
                                                                                item-text="label"
                                                                                item-value="value"
                                                                                multiple
                                                                                solo
                                                                            >
                                                                                <template v-slot:selection="data">
                                                                                    <v-chip
                                                                                        color="primary"
                                                                                        small
                                                                                    >
                                                                                        {{ data.item.label }}
                                                                                    </v-chip>
                                                                                </template>
                                                                                <template v-slot:prepend-item>
                                                                                    <v-list-item
                                                                                        @click="toggle"
                                                                                        ripple
                                                                                    >
                                                                                        <v-list-item-action>
                                                                                            <v-icon :color="userSelectedSearchAttributes.length > 0 ? 'primary darken-4' : ''">{{ icon }}</v-icon>
                                                                                        </v-list-item-action>
                                                                                        <v-list-item-content>
                                                                                            <v-list-item-title>{{ $t(`components.dataTableHeader.${userSelectedAllAttributes ? 'de' : ''}selectAll`) }}</v-list-item-title>
                                                                                        </v-list-item-content>
                                                                                    </v-list-item>
                                                                                    <v-divider class="mt-2"></v-divider>
                                                                                </template>
                                                                            </v-select>
                                                                        </v-col>
                                                                    </v-row>
                                                                    <v-data-table
                                                                        @click:row="rowClick"
                                                                        :headers="userHeaders"
                                                                        :items="users"
                                                                        :loading="loadingUsers"
                                                                        :options.sync="usersPagination"
                                                                        :server-items-length="usersPagination.total"
                                                                        :items-per-page="usersPagination.per_page"
                                                                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                                        :loading-text="$t('entities.users.fetching')"
                                                                        item-key="username"
                                                                        class="orders-users-table do-not-apply-hover"
                                                                        dense
                                                                        show-select
                                                                        single-select
                                                                        hide-default-footer
                                                                    >
                                                                        <template v-slot:item.data-table-select="item">
                                                                            <div style="position: relative">
                                                                                <v-checkbox
                                                                                    v-model="selectedUser"
                                                                                    :value="item.item.username"
                                                                                    class="select"
                                                                                >
                                                                                </v-checkbox>
                                                                                <v-icon
                                                                                    v-if="selectedUser.username === item.item.username"
                                                                                    color="white"
                                                                                    size="14"
                                                                                    class="active-checkmark"
                                                                                >
                                                                                    done
                                                                                </v-icon>
                                                                            </div>
                                                                        </template>
                                                                        <template v-slot:top>
                                                                            <v-toolbar flat>
                                                                                <v-toolbar-title>{{ $t('entities.users.title') }}</v-toolbar-title>
                                                                                <v-divider
                                                                                    class="mx-4"
                                                                                    inset
                                                                                    vertical
                                                                                ></v-divider>
                                                                                <small>{{ usersPagination.total }}</small>
                                                                                <v-spacer></v-spacer>
                                                                            </v-toolbar>
                                                                        </template>
                                                                        <template v-slot:item.identity.name="{ item }">
                                                                            <span>{{ item.identity && item.identity.name ? item.identity.name : '/' }}</span>
                                                                        </template>
                                                                        <template v-slot:item.email="{ item }">
                                                                            <span>{{ item.email ? item.email.address : '/' }}</span>
                                                                        </template>
                                                                        <template v-slot:no-data>
                                                                            <template v-if="usersPagination.search.length && searchingUser">
                                                                                {{ $t('entities.users.fetching') }}
                                                                            </template>
                                                                            <template v-else-if="usersPagination.search.length && !users.length">
                                                                                {{ $t('components.noData.noEntriesFound') }}
                                                                            </template>
                                                                            <template v-else>
                                                                                {{ $t('purchase.searchUser') }}
                                                                            </template>
                                                                        </template>
                                                                    </v-data-table>
                                                                    <DataTableFooter :pagination="usersPagination"/>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-card
                                                            class="mt-4"
                                                            elevation="3"
                                                        >
                                                            <v-row>
                                                                <v-col
                                                                    cols="12"
                                                                    class="pl-8 py-0"
                                                                >
                                                                    <h3 class="mt-4 grey--text text--darken-1">
                                                                        {{ $t('entities.carts.entity') }}
                                                                    </h3>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    class="py-0"
                                                                >
                                                                    <v-row align="center">
                                                                        <v-col>
                                                                            <v-toolbar flat>
                                                                                <v-row align="center">
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        class="text-center py-0"
                                                                                    >
                                                                                        <v-text-field
                                                                                            v-model="itemsPagination.search"
                                                                                            @click:append="() => (itemsPagination.search = '')"
                                                                                            @keydown.native.escape="itemsPagination.search = ''"
                                                                                            :append-icon="itemsPagination.search ? 'close': ''"
                                                                                            :label="$t('entities.orders.searchItemByName')"
                                                                                            :disabled="initialLoad || (!itemsPagination.total && loadingItems)"
                                                                                            prepend-inner-icon="search"
                                                                                            class="search mb-1"
                                                                                            hide-details
                                                                                            solo
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                </v-row>
                                                                                <template v-slot:extension>
                                                                                    <v-tabs
                                                                                        v-model="tab"
                                                                                        background-color="primary"
                                                                                        class="categories-header mx-1"
                                                                                        grow
                                                                                        dark
                                                                                    >
                                                                                        <v-tab
                                                                                            v-for="(category, key) in categories"
                                                                                            :key="`tab-${key}`"
                                                                                            :href="`#${key}`"
                                                                                            class="font-weight-bold"
                                                                                        >
                                                                                            {{ key }}
                                                                                        </v-tab>
                                                                                    </v-tabs>
                                                                                </template>
                                                                            </v-toolbar>
                                                                            <v-skeleton-loader
                                                                                v-if="initialLoad || (!itemsPagination.total && loadingItems)"
                                                                                :loading="initialLoad || (!itemsPagination.total && loadingItems)"
                                                                                type="table"
                                                                                height="65"
                                                                                class="px-4"
                                                                            />
                                                                            <v-tabs-items
                                                                                v-model="tab"
                                                                                class="mx-2"
                                                                                style="max-height: 340px; overflow-y: auto;"
                                                                            >
                                                                                <v-tab-item
                                                                                    v-for="(items, key) in categories"
                                                                                    :key="`tab-${key}`"
                                                                                    :value="key"
                                                                                >
                                                                                    <v-data-table
                                                                                        :headers="itemHeaders"
                                                                                        :items="items"
                                                                                        :loading="loadingItems"
                                                                                        :options.sync="itemOptions"
                                                                                        :server-items-length="itemsPagination.total"
                                                                                        :header-props="{ sortIcon: 'arrow_circle_up', sortByText: $t('miscellaneous.sortBy') }"
                                                                                        :no-results-text="$t('entities.orders.noItemsFound')"
                                                                                        :loading-text="$t('entities.items.fetching')"
                                                                                        class="do-not-apply-hover mx-3"
                                                                                        dense
                                                                                        hide-default-footer
                                                                                    >
                                                                                        <template v-slot:item.name="{ item }">
                                                                                            <template v-if="item.name !== 'Coins' && item.category === 'Coins'">
                                                                                                {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                {{ item.name }}
                                                                                            </template>
                                                                                        </template>
                                                                                        <template v-slot:item.price="{ item }">
                                                                                            <template v-if="item.name === 'Coins'">
                                                                                                <b>{{ item.coins_per_currency }} <CoinImage/> / 1 {{ item.currency }}</b>
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                <b>{{ format(item.price, true, item.currency) }}</b>
                                                                                            </template>
                                                                                        </template>
                                                                                        <template v-slot:item.quantity="{ item }">
                                                                                            <v-row
                                                                                                justify="center"
                                                                                                align="center"
                                                                                            >
                                                                                                <template v-if="item.name === 'Coins'">
                                                                                                    <v-col
                                                                                                        cols="auto"
                                                                                                        class="py-0"
                                                                                                    >
                                                                                                        <v-text-field
                                                                                                            v-model="coinsValue"
                                                                                                            v-money="money"
                                                                                                            prepend-icon="mdi-cash-multiple"
                                                                                                            ref="coins"
                                                                                                            style="width: 150px;"
                                                                                                        ></v-text-field>
                                                                                                    </v-col>
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                    <v-col
                                                                                                        cols="auto"
                                                                                                        class="py-0"
                                                                                                    >
                                                                                                        <v-btn
                                                                                                            @click="decrementQuantity(item)"
                                                                                                            :disabled="item.quantity < 1"
                                                                                                            color="primary"
                                                                                                            small
                                                                                                            rounded
                                                                                                        >
                                                                                                            <v-icon
                                                                                                                color="white"
                                                                                                                small
                                                                                                            >
                                                                                                                exposure_neg_1
                                                                                                            </v-icon>
                                                                                                        </v-btn>
                                                                                                    </v-col>
                                                                                                    <v-col
                                                                                                        cols="auto"
                                                                                                        class="my-0 py-0"
                                                                                                    >
                                                                                                        <v-text-field
                                                                                                            :value="item.quantity"
                                                                                                            v-on:keyup="handleChange(item, $event)"
                                                                                                            :key="item.type + '-' + item.id"
                                                                                                            type="number"
                                                                                                            class="quantity-input my-0 py-0"
                                                                                                        >
                                                                                                        </v-text-field>
                                                                                                    </v-col>
                                                                                                    <v-col
                                                                                                        cols="auto"
                                                                                                        class="py-0"
                                                                                                    >
                                                                                                        <v-btn
                                                                                                            @click="incrementQuantity(item)"
                                                                                                            color="primary"
                                                                                                            small
                                                                                                            rounded
                                                                                                        >
                                                                                                            <v-icon
                                                                                                                color="white"
                                                                                                                small
                                                                                                            >
                                                                                                                plus_one
                                                                                                            </v-icon>
                                                                                                        </v-btn>
                                                                                                    </v-col>
                                                                                                </template>
                                                                                                <v-col
                                                                                                    cols="auto"
                                                                                                    class="py-0"
                                                                                                >
                                                                                                    <v-btn
                                                                                                        @click="removeItem(item)"
                                                                                                        :disabled="item.quantity < 1"
                                                                                                        color="error"
                                                                                                        small
                                                                                                        rounded
                                                                                                    >
                                                                                                        <v-icon
                                                                                                            color="white"
                                                                                                            small
                                                                                                        >
                                                                                                            remove_shopping_cart
                                                                                                        </v-icon>
                                                                                                    </v-btn>
                                                                                                </v-col>
                                                                                            </v-row>
                                                                                        </template>
                                                                                    </v-data-table>
                                                                                </v-tab-item>
                                                                            </v-tabs-items>
                                                                            <v-row v-if="itemsPagination.total < 1 && !loadingItems">
                                                                                <v-col class="text-center my-4">
                                                                                    <template v-if="itemsPagination.search.length">
                                                                                        <p class="mb-0">{{ $t('entities.orders.noItemsFoundForSearch', { search: itemsPagination.search }) }}</p>
                                                                                        <p class="mb-0">{{ $t('entities.orders.tryDifferentSearch') }}</p>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <p class="mb-0">{{ $t('entities.orders.noItemsFound') }}</p>
                                                                                    </template>
                                                                                </v-col>
                                                                            </v-row>
                                                                            <v-footer
                                                                                color="primary"
                                                                                class="footer mx-5"
                                                                            >
                                                                                <v-row justify="center">
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        class="text-center white--text pt-4"
                                                                                    >
                                                                                        <template v-if="loadingItems">
                                                                                            <v-skeleton-loader
                                                                                                :loading="loadingItems"
                                                                                                type="text"
                                                                                                width="150"
                                                                                                style="margin: 0 auto;"
                                                                                            />
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{ $t('entities.orders.totalItems') }}: {{ itemsPagination.total }}
                                                                                        </template>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-footer>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    class="px-8"
                                                                >
                                                                    <h3 class="grey--text text--darken-1">
                                                                        {{ $t('entities.items.title') }}
                                                                    </h3>
                                                                    <template v-if="selectedItems.length">
                                                                        <v-simple-table
                                                                            class="do-not-apply-hover"
                                                                            dense
                                                                        >
                                                                            <template v-slot:default>
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th class="text-left pl-1">
                                                                                            {{ $t('generalAttributes.name') }}
                                                                                        </th>
                                                                                        <th class="text-left">
                                                                                            {{ $t('generalAttributes.quantity') }}
                                                                                        </th>
                                                                                        <th class="text-left">
                                                                                            {{ $t('generalAttributes.price') }}
                                                                                        </th>
                                                                                        <th class="text-right">
                                                                                            {{ $t('entities.invoices.printData.amount') }}
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr
                                                                                        v-for="item in selectedItems"
                                                                                        :key="item.name"
                                                                                    >
                                                                                        <td class="pl-0">
                                                                                            <template v-if="item.name !== 'Coins' && item.category === 'Coins'">
                                                                                                {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                {{ item.name }}
                                                                                            </template>
                                                                                        </td>
                                                                                        <td class="font-weight-bold">
                                                                                            {{ addThousandSeparator(item.quantity) }}
                                                                                            <template v-if="item.name === 'Coins'">
                                                                                                <CoinImage/>
                                                                                            </template>
                                                                                        </td>
                                                                                        <td class="font-weight-bold">
                                                                                            <template v-if="item.name === 'Coins'">
                                                                                                {{ item.coins_per_currency }} <CoinImage/> / 1 {{ item.currency }}
                                                                                            </template>
                                                                                            <template v-else>
                                                                                                {{ format(item.price, true, item.currency) }}
                                                                                            </template>
                                                                                        </td>
                                                                                        <td class="text-right font-weight-bold">{{ format(item.price * item.quantity, false) }}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colspan="2"></td>
                                                                                        <td>{{ $t('entities.invoices.printData.total') }}</td>
                                                                                        <td class="text-right font-weight-bold">{{ totalPrice }}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </template>
                                                                        </v-simple-table>
                                                                    </template>
                                                                    <template v-else>
                                                                        <i class="grey--text">{{ $t('entities.orders.notSelected') }}</i>
                                                                    </template>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col
                                                        cols="12"
                                                        class="px-6"
                                                    >
                                                        <v-card
                                                            elevation="3"
                                                            class="py-3 "
                                                        >
                                                            <v-row class="px-6">
                                                                <v-col
                                                                    cols="12"
                                                                    class="py-0"
                                                                >
                                                                    <h3 class="mt-4 grey--text text--darken-1">
                                                                        {{ $t('entities.orders.generalInformation') }}
                                                                    </h3>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                >
                                                                    <v-select
                                                                        v-model="editedEntity.delivery_status"
                                                                        :items="delivery_statuses"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="mdi-truck"
                                                                        solo
                                                                        chips
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.orders.attributes.deliveryStatus') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ $t('entities.orders.attributes.deliveryStatus') }}:
                                                                            <v-chip
                                                                                :input-value="item.status"
                                                                                :color="orderStatusColor(editedEntity.delivery_status)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${editedEntity.delivery_status}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            <v-chip
                                                                                :input-value="data.item"
                                                                                :color="orderStatusColor(data.item)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${data.item}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                >
                                                                    <v-select
                                                                        v-model="editedEntity.payment_status"
                                                                        :items="payment_statuses"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="mdi-account-cash"
                                                                        solo
                                                                        chips
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.orders.attributes.paymentStatus') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ $t('entities.orders.attributes.paymentStatus') }}:
                                                                            <v-chip
                                                                                :input-value="item.status"
                                                                                :color="orderStatusColor(editedEntity.payment_status)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${editedEntity.payment_status}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            <v-chip
                                                                                :input-value="data.item"
                                                                                :color="orderStatusColor(data.item)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${data.item}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                >
                                                                    <v-select
                                                                        v-model="editedEntity.status"
                                                                        :items="statuses"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="label"
                                                                        solo
                                                                        chips
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('entities.orders.attributes.status') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="{ item }">
                                                                            {{ $t('entities.orders.attributes.status') }}:
                                                                            <v-chip
                                                                                :input-value="item.status"
                                                                                :color="orderStatusColor(editedEntity.status)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${editedEntity.status}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            <v-chip
                                                                                :input-value="data.item"
                                                                                :color="orderStatusColor(data.item)"
                                                                                class="font-weight-bold"
                                                                                text-color="white"
                                                                                small
                                                                            >
                                                                                {{ $t(`entities.orders.statuses.${data.item}`) }}
                                                                            </v-chip>
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                    class="pb-0"
                                                                >
                                                                    <v-select
                                                                        :value="editedEntity.payment_method.id"
                                                                        @change="editedEntity.payment_method = $event"
                                                                        :items="paymentMethods"
                                                                        :rules="[v => !!v || $t('validation.required')]"
                                                                        :menu-props="{ bottom: true, offsetY: true }"
                                                                        item-text="name"
                                                                        item-value="id"
                                                                        class="do-not-apply-transform"
                                                                        prepend-inner-icon="mdi-cash"
                                                                        return-object
                                                                        solo
                                                                    >
                                                                        <template v-slot:label>
                                                                            {{ $t('miscellaneous.paymentMethod') }}
                                                                            <span :style="{ color: 'red' }">*</span>
                                                                        </template>
                                                                        <template v-slot:selection="data">
                                                                            {{ $t('miscellaneous.paymentMethod') }}: {{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(data.item.name)}`) }}
                                                                        </template>
                                                                        <template v-slot:item="data">
                                                                            {{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(data.item.name)}`) }}
                                                                        </template>
                                                                    </v-select>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                >
                                                                    <v-text-field
                                                                        :value="editedEntity.referred_from"
                                                                        @change="v => (editedEntity.referred_from = v)"
                                                                        :label="$t('entities.orders.attributes.referredFrom')"
                                                                        prepend-icon="mdi-format-title"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="4"
                                                                >
                                                                    <v-text-field
                                                                        :value="editedEntity.serial_number"
                                                                        @change="v => (editedEntity.serial_number = v)"
                                                                        :label="$t('entities.orders.attributes.serialNumber')"
                                                                        prepend-icon="confirmation_number"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-expansion-panels v-model="billing_information">
                                                                        <v-expansion-panel>
                                                                            <v-expansion-panel-header
                                                                                class="px-6"
                                                                                disable-icon-rotate
                                                                            >
                                                                                {{ $t('entities.orders.attributes.billingInformation') }}
                                                                                <template v-slot:actions>
                                                                                    <template v-if="billing_information === 0">
                                                                                        <v-icon color="success">
                                                                                            check_circle
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <v-icon color="error">
                                                                                            cancel
                                                                                        </v-icon>
                                                                                    </template>
                                                                                </template>
                                                                            </v-expansion-panel-header>
                                                                            <v-expansion-panel-content class="px-6">
                                                                                <v-row
                                                                                    align="center"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.name"
                                                                                            @change="v => (editedEntity.billing_information.name = v)"
                                                                                            :label="$t('entities.users.attributes.name')"
                                                                                            prepend-icon="person"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.surname"
                                                                                            @change="v => (editedEntity.billing_information.surname = v)"
                                                                                            :label="$t('entities.users.attributes.surname')"
                                                                                            prepend-icon="person"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.email"
                                                                                            @change="v => (editedEntity.billing_information.email = v)"
                                                                                            :label="$t('entities.users.attributes.email')"
                                                                                            prepend-icon="email"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.address"
                                                                                            @change="v => (editedEntity.billing_information.address = v)"
                                                                                            :label="$t('generalAttributes.address')"
                                                                                            prepend-icon="house"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.phone"
                                                                                            @change="v => (editedEntity.billing_information.phone = v)"
                                                                                            :label="$t('generalAttributes.phone')"
                                                                                            prepend-icon="phone"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.city"
                                                                                            @change="v => (editedEntity.billing_information.city = v)"
                                                                                            :label="$t('generalAttributes.city')"
                                                                                            prepend-icon="business"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.postal_code"
                                                                                            @change="v => (editedEntity.billing_information.postal_code = v)"
                                                                                            :label="$t('generalAttributes.postalCode')"
                                                                                            prepend-icon="mdi-mailbox"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.note"
                                                                                            @change="v => (editedEntity.billing_information.note = v)"
                                                                                            :label="$t('entities.orders.attributes.note')"
                                                                                            prepend-icon="mdi-text"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-expansion-panel-content>
                                                                        </v-expansion-panel>
                                                                    </v-expansion-panels>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    class="py-0"
                                                                >
                                                                    <v-expansion-panels v-model="editedEntity.billing_information.company.r1">
                                                                        <v-expansion-panel>
                                                                            <v-expansion-panel-header
                                                                                class="px-6"
                                                                                disable-icon-rotate
                                                                            >
                                                                                {{ $t('entities.orders.issueForCompany') }}
                                                                                <template v-slot:actions>
                                                                                    <template v-if="editedEntity.billing_information.company.r1 === 0">
                                                                                        <v-icon color="success">
                                                                                            check_circle
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <v-icon color="error">
                                                                                            cancel
                                                                                        </v-icon>
                                                                                    </template>
                                                                                </template>
                                                                            </v-expansion-panel-header>
                                                                            <v-expansion-panel-content class="px-6">
                                                                                <v-row
                                                                                    align="center"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                        class="position-relative"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.company.name"
                                                                                            @change="v => (editedEntity.billing_information.company.name = v)"
                                                                                            :rules="editedEntity.billing_information.company.r1 === 0 ? [required] : []"
                                                                                            :label="$t('entities.users.attributes.name')"
                                                                                            prepend-icon="business"
                                                                                        ></v-text-field>
                                                                                        <span class="required-fields">*</span>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                        class="position-relative"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.billing_information.company.pidn"
                                                                                            @change="v => (editedEntity.billing_information.company.pidn = v)"
                                                                                            :rules="editedEntity.billing_information.company.r1 === 0 ? [required] : []"
                                                                                            :label="$t('generalAttributes.pidn')"
                                                                                            prepend-icon="short_text"
                                                                                        ></v-text-field>
                                                                                        <span class="required-fields">*</span>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        class="position-relative pt-0"
                                                                                    >
                                                                                        <v-text-field
                                                                                            v-model="editedEntity.billing_information.company.address"
                                                                                            :rules="editedEntity.billing_information.company.r1 === 0 ? [required] : []"
                                                                                            :label="$t('generalAttributes.address')"
                                                                                            prepend-icon="business"
                                                                                        ></v-text-field>
                                                                                        <span class="required-fields">*</span>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-expansion-panel-content>
                                                                        </v-expansion-panel>
                                                                    </v-expansion-panels>
                                                                </v-col>
                                                                <v-col cols="12">
                                                                    <v-expansion-panels v-model="editedEntity.billing_and_shipping_equal">
                                                                        <v-expansion-panel>
                                                                            <v-expansion-panel-header
                                                                                class="px-6"
                                                                                disable-icon-rotate
                                                                            >
                                                                                {{ $t('entities.orders.shippingAndBillingEqual') }}
                                                                                <template v-slot:actions>
                                                                                    <template v-if="editedEntity.billing_and_shipping_equal === 0">
                                                                                        <v-icon color="error">
                                                                                            cancel
                                                                                        </v-icon>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <v-icon color="success">
                                                                                            check_circle
                                                                                        </v-icon>
                                                                                    </template>
                                                                                </template>
                                                                            </v-expansion-panel-header>
                                                                            <v-expansion-panel-content class="px-6">
                                                                                <v-row
                                                                                    align="center"
                                                                                    class="pb-4"
                                                                                >
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.name"
                                                                                            @change="v => (editedEntity.shipping_information.name = v)"
                                                                                            :label="$t('entities.users.attributes.name')"
                                                                                            prepend-icon="person"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.surname"
                                                                                            @change="v => (editedEntity.shipping_information.surname = v)"
                                                                                            :label="$t('entities.users.attributes.surname')"
                                                                                            prepend-icon="person"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.email"
                                                                                            @change="v => (editedEntity.shipping_information.email = v)"
                                                                                            :label="$t('entities.users.attributes.email')"
                                                                                            prepend-icon="email"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.address"
                                                                                            @change="v => (editedEntity.shipping_information.address = v)"
                                                                                            :label="$t('generalAttributes.address')"
                                                                                            prepend-icon="house"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.phone"
                                                                                            @change="v => (editedEntity.shipping_information.phone = v)"
                                                                                            :label="$t('generalAttributes.phone')"
                                                                                            prepend-icon="phone"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.city"
                                                                                            @change="v => (editedEntity.shipping_information.city = v)"
                                                                                            :label="$t('generalAttributes.city')"
                                                                                            prepend-icon="business"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.postal_code"
                                                                                            @change="v => (editedEntity.shipping_information.postal_code = v)"
                                                                                            :label="$t('generalAttributes.postalCode')"
                                                                                            prepend-icon="mdi-mailbox"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                    <v-col
                                                                                        cols="12"
                                                                                        sm="6"
                                                                                    >
                                                                                        <v-text-field
                                                                                            :value="editedEntity.shipping_information.note"
                                                                                            @change="v => (editedEntity.shipping_information.note = v)"
                                                                                            :label="$t('entities.orders.attributes.note')"
                                                                                            prepend-icon="mdi-text"
                                                                                        ></v-text-field>
                                                                                    </v-col>
                                                                                </v-row>
                                                                            </v-expansion-panel-content>
                                                                        </v-expansion-panel>
                                                                    </v-expansion-panels>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    class="py-0"
                                                                >
                                                                    <v-text-field
                                                                        :value="editedEntity.note"
                                                                        @change="v => (editedEntity.note = v)"
                                                                        :label="$t('entities.orders.attributes.note')"
                                                                        prepend-icon="mdi-text"
                                                                    ></v-text-field>
                                                                </v-col>
                                                                <v-col
                                                                    cols="12"
                                                                    sm="6"
                                                                    class="py-0"
                                                                >
                                                                    <v-switch
                                                                        v-model="editedEntity.delivery"
                                                                        :label="$t('entities.orders.attributes.delivery')"
                                                                        prepend-icon="done"
                                                                        inset
                                                                    ></v-switch>
                                                                </v-col>
                                                            </v-row>
                                                        </v-card>
                                                    </v-col>
                                                </v-row>
                                                <ValidationErrors
                                                    v-if="validationErrors.length"
                                                    :errors="validationErrors"
                                                    class="mt-6"
                                                />
                                            </v-form>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <EditCardActions
                                    :save="save"
                                    :saving="saving"
                                    :closeEditDialog="closeEditDialog"
                                />
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="viewDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <v-toolbar
                                        class="elevation-2"
                                        color="primary"
                                    >
                                        <v-toolbar-title class="ml-0">
                                            <span class="font-weight-bold white--text">{{ $t('entities.orders.viewEntity') }}</span>
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="viewDialog = false"
                                            icon
                                            dark
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-container>
                                        <v-card class="pb-2">
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr v-if="editedEntity.invoices.length">
                                                            <td>{{ $t('entities.invoices.title') }}</td>
                                                            <td colspan="6">
                                                                <v-chip
                                                                    v-for="(invoice, key) in editedEntity.invoices"
                                                                    :key="key"
                                                                    class="font-weight-bold mr-4"
                                                                    color="green darken-2"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ invoice.label }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ $t('entities.orders.attributes.paymentStatus') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="editedEntity.payment_status"
                                                                    :color="orderStatusColor(editedEntity.payment_status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${editedEntity.payment_status}`) }}
                                                                </v-chip>
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.status') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="editedEntity.status"
                                                                    :color="orderStatusColor(editedEntity.status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${editedEntity.status}`) }}
                                                                </v-chip>
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.deliveryStatus') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="editedEntity.delivery_status"
                                                                    :color="orderStatusColor(editedEntity.delivery_status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${editedEntity.delivery_status}`) }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.user') }}</td>
                                                            <td
                                                                v-if="editedEntity.user"
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.user.username }}
                                                            </td>
                                                            <td
                                                                v-else
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                /
                                                            </td>
                                                            <td>{{ $t('miscellaneous.arena') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.arena ? editedEntity.arena.name : '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ fromUtcDateTimeToCurrent(editedEntity.created_at, true, true) }}
                                                            </td>
                                                            <td>{{ $t('generalAttributes.description') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.description }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.paymentMethod') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.payment_method.name ? $t(`miscellaneous.paymentMethods.${titleToCamelCase(editedEntity.payment_method.name)}`) : '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.note') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.note || '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.orders.attributes.serialNumber') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.serial_number || '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.referredFrom') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ editedEntity.referred_from || '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ $t('miscellaneous.items') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.quantity') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.price') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="text-right"
                                                            >
                                                                {{ $t('generalAttributes.totalPrice') }}
                                                            </td>
                                                        </tr>
                                                        <template v-if="editedEntity.cart && editedEntity.cart.items && editedEntity.cart.items.length">
                                                            <tr
                                                                v-for="(item, key) in editedEntity.cart.items"
                                                                :key="key"
                                                                class="font-weight-bold"
                                                            >

                                                                <td colspan="2">
                                                                    <template v-if="item.name !== 'Coins' && item.category.name === 'Coins'">
                                                                        {{ item.name }} ({{ item.category_attributes ? item.category_attributes[0]['value'] : '' }} <CoinImage/>)
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ item.name }}
                                                                    </template>
                                                                </td>
                                                                <td colspan="2">
                                                                    {{ addThousandSeparator(item.quantity) }}
                                                                    <template v-if="item.name === 'Coins'">
                                                                        <CoinImage/>
                                                                    </template>
                                                                </td>
                                                                <td colspan="2">
                                                                    <template v-if="item.name === 'Coins'">
                                                                        {{ editedEntity.arena.company.country.coins_per_currency }} <CoinImage/> / 1 {{ editedEntity.arena.company.country.currency }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ format(item.price, true, editedEntity.arena.company.country.currency) }}
                                                                    </template>
                                                                </td>
                                                                <td
                                                                    colspan="2"
                                                                    class="text-right"
                                                                >
                                                                    {{ format(item.price * item.quantity, true, editedEntity.arena.company.country.currency) }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    class="font-weight-bold"
                                                                >
                                                                    /
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-if="viewDialog">
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.net') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(editedEntity.cart.net, true, editedEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.vat') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(editedEntity.cart.gross - editedEntity.cart.net, true, editedEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('entities.orders.attributes.deliveryAmount') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(editedEntity.delivery_amount, true, editedEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.gross') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(editedEntity.cart.gross + editedEntity.delivery_amount, true, editedEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                            <v-row>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="my-3 pl-6"
                                                    :class="{ 'd-flex flex-column' : billingAndShippingInformationOpened, 'text-center' : !editedEntity.billing_information }"
                                                >
                                                    <template v-if="!editedEntity.billing_information">
                                                        <p>
                                                            {{ $t('entities.orders.noBillingInformation' )}}
                                                        </p>
                                                    </template>
                                                    <template v-else>
                                                        <v-expansion-panels
                                                            v-model="billing_information_opened"
                                                            :class="{ 'flex d-flex flex-column' : billingAndShippingInformationOpened }"
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="px-4">
                                                                    {{ $t('entities.orders.attributes.billingInformation') }}
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="pb-2">
                                                                    <v-simple-table
                                                                        class="do-not-apply-hover"
                                                                        dense
                                                                    >
                                                                        <template v-slot:default>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.name') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.billing_information.name && editedEntity.billing_information.surname">
                                                                                            {{ editedEntity.billing_information.name }} {{ editedEntity.billing_information.surname }}
                                                                                        </template>
                                                                                        <template v-else-if="editedEntity.billing_information.name && !editedEntity.billing_information.surname">
                                                                                            {{ editedEntity.billing_information.name }}
                                                                                        </template>
                                                                                        <template v-else-if="!editedEntity.billing_information.name && editedEntity.billing_information.surname">
                                                                                            {{ editedEntity.billing_information.surname }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('entities.users.attributes.email') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.billing_information.email">
                                                                                            <CopyTooltip :text="editedEntity.billing_information.email"/>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.phone') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.billing_information.phone">
                                                                                            <CopyTooltip :text="editedEntity.billing_information.phone"/>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.address') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.billing_information.address && editedEntity.billing_information.city && editedEntity.billing_information.postal_code">
                                                                                            {{ editedEntity.billing_information.address }}, {{ editedEntity.billing_information.city }} {{ editedEntity.billing_information.postal_code }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{ editedEntity.billing_information.address ? `${editedEntity.billing_information.address},` : '/' }} {{ editedEntity.billing_information.city || '' }} {{ editedEntity.billing_information.postal_code || '' }}
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('entities.orders.attributes.note') }}</td>
                                                                                    <td class="font-weight-bold">{{ editedEntity.billing_information.note || '/' }}</td>
                                                                                </tr>
                                                                                <tr v-if="editedEntity.billing_information.company.r1">
                                                                                    <td>{{ $t('entities.companies.entity') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        {{ editedEntity.billing_information.company.name }}, {{ editedEntity.billing_information.company.address }}, {{ editedEntity.billing_information.company.pidn }}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </template>
                                                                    </v-simple-table>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </template>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    class="my-3 pr-6"
                                                    :class="{ 'd-flex flex-column' : billingAndShippingInformationOpened, 'text-center' : !editedEntity.shipping_information }"
                                                >
                                                    <template v-if="!editedEntity.shipping_information">
                                                        <p>
                                                            {{ $t('entities.orders.noShippingInformation' )}}
                                                        </p>
                                                    </template>
                                                    <template v-else>
                                                        <v-expansion-panels
                                                            v-model="shipping_information_opened"
                                                            :class="{ 'flex d-flex flex-column' : billingAndShippingInformationOpened }"
                                                        >
                                                            <v-expansion-panel>
                                                                <v-expansion-panel-header class="px-4">
                                                                    {{ $t('entities.orders.attributes.shippingInformation') }}
                                                                </v-expansion-panel-header>
                                                                <v-expansion-panel-content class="pb-2">
                                                                    <v-simple-table
                                                                        class="do-not-apply-hover"
                                                                        dense
                                                                    >
                                                                        <template v-slot:default>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.name') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.shipping_information.name && editedEntity.shipping_information.surname">
                                                                                            {{ editedEntity.shipping_information.name }} {{ editedEntity.shipping_information.surname }}
                                                                                        </template>
                                                                                        <template v-else-if="editedEntity.shipping_information.name && !editedEntity.shipping_information.surname">
                                                                                            {{ editedEntity.shipping_information.name }}
                                                                                        </template>
                                                                                        <template v-else-if="!editedEntity.shipping_information.name && editedEntity.shipping_information.surname">
                                                                                            {{ editedEntity.shipping_information.surname }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('entities.users.attributes.email') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.shipping_information.email">
                                                                                            <CopyTooltip :text="editedEntity.shipping_information.email"/>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.phone') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.shipping_information.phone">
                                                                                            <CopyTooltip :text="editedEntity.shipping_information.phone"/>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            /
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('generalAttributes.address') }}</td>
                                                                                    <td class="font-weight-bold">
                                                                                        <template v-if="editedEntity.shipping_information.address && editedEntity.shipping_information.city && editedEntity.shipping_information.postal_code">
                                                                                            {{ editedEntity.shipping_information.address }}, {{ editedEntity.shipping_information.city }} {{ editedEntity.shipping_information.postal_code }}
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            {{ editedEntity.shipping_information.address ? `${editedEntity.shipping_information.address},` : '/' }} {{ editedEntity.shipping_information.city || '' }} {{ editedEntity.shipping_information.postal_code || '' }}
                                                                                        </template>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{{ $t('entities.orders.attributes.note') }}</td>
                                                                                    <td class="font-weight-bold">{{ editedEntity.shipping_information.note || '/' }}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </template>
                                                                    </v-simple-table>
                                                                </v-expansion-panel-content>
                                                            </v-expansion-panel>
                                                        </v-expansion-panels>
                                                    </template>
                                                </v-col>
                                            </v-row>
                                            <ValidationErrors
                                                v-if="validationErrors.length"
                                                :errors="validationErrors"
                                                class="mt-2"
                                            />
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-btn
                                        @click="createInvoice"
                                        :disabled="creatingInvoice"
                                        :loading="creatingInvoice"
                                        color="success"
                                        class="mb-2"
                                    >
                                        <span>{{ creatingInvoice ? $t('purchase.creatingInvoice') : $t(`entities.orders.create${editedEntity.invoices.length ? 'Another': '' }Invoice`) }}</span>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="viewDialog = false"
                                        color="dark darken-1"
                                        text
                                    >
                                        {{ $t('miscellaneous.ok') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="deleteDialog"
                            max-width="1000px"
                        >
                            <v-card>
                                <v-card-text class="px-0 pb-0">
                                    <DeleteToolbar
                                        :text="$t('entities.orders.deleteEntity')"
                                        :closeDeleteDialog="closeDeleteDialog"
                                    />
                                    <v-container>
                                        <v-card>
                                            <v-simple-table
                                                class="do-not-apply-hover"
                                                dense
                                            >
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr>
                                                            <td colspan="2">{{ $t('entities.orders.attributes.paymentStatus') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="toDeleteEntity.payment_status"
                                                                    :color="orderStatusColor(toDeleteEntity.payment_status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${toDeleteEntity.payment_status}`) }}
                                                                </v-chip>
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.status') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="toDeleteEntity.status"
                                                                    :color="orderStatusColor(toDeleteEntity.status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${toDeleteEntity.status}`) }}
                                                                </v-chip>
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.deliveryStatus') }}</td>
                                                            <td>
                                                                <v-chip
                                                                    class="font-weight-bold"
                                                                    :input-value="toDeleteEntity.delivery_status"
                                                                    :color="orderStatusColor(toDeleteEntity.delivery_status)"
                                                                    text-color="white"
                                                                    small
                                                                >
                                                                    {{ $t(`entities.orders.statuses.${toDeleteEntity.delivery_status}`) }}
                                                                </v-chip>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.user') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                <template v-if="toDeleteEntity.user">
                                                                    <span>{{ $t('miscellaneous.user') }}</span>
                                                                    <br>
                                                                    <span>{{ $t('generalAttributes.name') }}: <b>{{ toDeleteEntity.user.name || '/' }}</b></span>
                                                                    <br>
                                                                    <span>{{ $t('entities.users.attributes.username') }}: <b>{{ toDeleteEntity.user.username }}</b></span>
                                                                    <br>
                                                                    <span>{{ $t('entities.users.attributes.email') }}: <b>{{ toDeleteEntity.user.email ? toDeleteEntity.user.email.address : '/' }}</b></span>
                                                                </template>
                                                            </td>
                                                            <td>{{ $t('miscellaneous.arena') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.arena ? toDeleteEntity.arena.name : '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.createdAt') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ fromUtcDateTimeToCurrent(toDeleteEntity.created_at, true, true) }}
                                                            </td>
                                                            <td>{{ $t('generalAttributes.description') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.description }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('miscellaneous.paymentMethod') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.payment_method.name ? $t(`miscellaneous.paymentMethods.${titleToCamelCase(toDeleteEntity.payment_method.name)}`) : '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.note') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.note || '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>{{ $t('entities.orders.attributes.serialNumber') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.serial_number || '/' }}
                                                            </td>
                                                            <td>{{ $t('entities.orders.attributes.referredFrom') }}</td>
                                                            <td
                                                                colspan="3"
                                                                class="font-weight-bold"
                                                            >
                                                                {{ toDeleteEntity.referred_from || '/' }}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2">{{ $t('miscellaneous.items') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.quantity') }}</td>
                                                            <td colspan="2">{{ $t('generalAttributes.price') }}</td>
                                                            <td
                                                                colspan="2"
                                                                class="text-right"
                                                            >
                                                                {{ $t('generalAttributes.totalPrice') }}
                                                            </td>
                                                        </tr>
                                                        <template v-if="toDeleteEntity.cart && toDeleteEntity.cart.items && toDeleteEntity.cart.items.length">
                                                            <tr
                                                                v-for="(item, key) in toDeleteEntity.cart.items"
                                                                :key="key"
                                                                class="font-weight-bold"
                                                            >
                                                                <td colspan="2">{{ item.name }}</td>
                                                                <td colspan="2">{{ item.quantity }}</td>
                                                                <td colspan="2">{{ format(item.price, true, toDeleteEntity.arena.company.country.currency) }}</td>
                                                                <td
                                                                    colspan="2"
                                                                    class="text-right"
                                                                >
                                                                    {{ format(item.price * item.quantity, true, toDeleteEntity.arena.company.country.currency) }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-else>
                                                            <tr>
                                                                <td
                                                                    colspan="6"
                                                                    class="font-weight-bold"
                                                                >
                                                                    /
                                                                </td>
                                                            </tr>
                                                        </template>
                                                        <template v-if="deleteDialog">
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.net') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(toDeleteEntity.cart.net, true, toDeleteEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.vat') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(editedEntity.cart.gross - editedEntity.cart.net, true, toDeleteEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('entities.orders.attributes.deliveryAmount') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(toDeleteEntity.delivery_amount, true, toDeleteEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="5"></td>
                                                                <td>{{ $t('miscellaneous.gross') }}</td>
                                                                <td class="text-right font-weight-bold">{{ format(toDeleteEntity.cart.gross, true, toDeleteEntity.arena.company.country.currency) }}</td>
                                                            </tr>
                                                        </template>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </v-container>
                                </v-card-text>
                                <DeleteCardActions
                                    :deleteEntity="deleteEntity"
                                    :deleting="deleting"
                                    :closeDeleteDialog="closeDeleteDialog"
                                />
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.user="{ item }">
                    <span>{{ item.user ? item.user.username || '/'  : '/' }}</span>
                </template>
                <template v-slot:item.payment_method.name="{ item }">
                    <span>{{ $t(`miscellaneous.paymentMethods.${titleToCamelCase(item.payment_method.name)}`) }}</span>
                </template>
                <template v-slot:item.cart.gross="{ item }">
                    <span><b>{{ format(item.cart.gross, true, item.arena.company.country.currency) }}</b></span>
                </template>
                <template v-slot:item.delivery_status="{ item }">
                    <v-chip
                        class="font-weight-bold"
                        :color="orderStatusColor(item.delivery_status)"
                        text-color="white"
                        small
                    >
                        {{ $t(`entities.orders.statuses.${item.delivery_status}`) }}
                    </v-chip>
                </template>
                <template v-slot:item.payment_status="{ item }">
                    <v-chip
                        class="font-weight-bold"
                        :color="orderStatusColor(item.payment_status)"
                        text-color="white"
                        small
                    >
                        {{ $t(`entities.orders.statuses.${item.payment_status}`) }}
                    </v-chip>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-chip
                        class="font-weight-bold"
                        :color="orderStatusColor(item.status)"
                        text-color="white"
                        small
                    >
                        {{ $t(`entities.orders.statuses.${item.status}`) }}
                    </v-chip>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{ fromUtcDateTimeToCurrent(item.created_at, true, true) }}
                </template>
                <template v-slot:item.action="{ item }">
                    <ViewButton
                        :text="$t('entities.orders.viewEntity')"
                        :item="item"
                        :openViewDialog="openViewDialog"
                    />
                    <EditButton
                        :text="$t('entities.orders.editEntity')"
                        :item="item"
                        :openEditDialog="openEditDialog"
                    />
                    <DeleteButton
                        :text="$t('entities.orders.deleteEntity')"
                        :item="item"
                        :openDeleteDialog="openDeleteDialog"
                    />
                </template>
                <template v-slot:no-data>
                    <NoData
                        :pagination="pagination"
                        :typing="typing"
                        :fetchEntities="fetchEntities"
                    />
                </template>
            </v-data-table>
            <DataTableFooter
                :pagination="pagination"
                :loading="loading"
            />
        </v-card>
        <Snackbar
            :show.sync="snackbar.show"
            :color="snackbar.color"
            :message="snackbar.message"
            :icon="snackbar.icon"
            :timeout="snackbar.timeout"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import NoData from '@/components/crud/NoData.vue';
import RefreshButton from '@/components/crud/RefreshButton.vue';
import CoinImage from '@/components/CoinImage.vue';
import ViewButton from '@/components/crud/view/ViewButton.vue';
import EditButton from '@/components/crud/edit/EditButton.vue';
import EditToolbar from '@/components/crud/edit/EditToolbar.vue';
import EditCardActions from '@/components/crud/edit/EditCardActions.vue';
import DeleteButton from '@/components/crud/delete/DeleteButton.vue';
import DeleteToolbar from '@/components/crud/delete/DeleteToolbar.vue';
import DeleteCardActions from '@/components/crud/delete/DeleteCardActions.vue';
import RequiredFields from '@/components/crud/RequiredFields.vue';
import DataTableHeader from '@/components/crud/DataTableHeader.vue';
import DataTableSkeletonLoader from '@/components/crud/DataTableSkeletonLoader.vue';
import DataTableFooter from '@/components/crud/DataTableFooter.vue';
import ValidationErrors from '@/components/crud/ValidationErrors.vue';
import RowsPerPage from '@/components/crud/RowsPerPage.vue';
import Snackbar from '@/components/Snackbar.vue';
import CopyTooltip from '@/components/CopyTooltip.vue';
import Order from '@/models/shop/Order';
import PaymentMethod from '@/models/misc/PaymentMethod';
import User from '@/models/users/User';
import Item from '@/models/shop/Item';

import convertDate from '@/mixins/convert-date';
import user from '@/mixins/crud/computed/user';
import currency from '@/mixins/currency';
import thousandSeparator from '@/mixins/thousand-separator';
import convertCase from '@/mixins/convert-case';
import isRole from '@/mixins/crud/computed/isRole';
import required from '@/mixins/rules/required';
import { VMoney } from 'v-money';
import { editDialog } from '@/mixins/crud/watch/edit-dialog.js';
import { deleteEntity } from '@/mixins/crud/methods/delete-entity.js';
import { paginationWatch } from '@/mixins/crud/watch/pagination.js';
import { showSnackbar } from "@/mixins/crud/methods/show-snackbar";
import { axiosCatch } from '@/mixins/crud/methods/axios-catch.js';

export default Vue.extend({
    components: {
        NoData,
        RefreshButton,
        CoinImage,
        ViewButton,
        EditButton,
        EditToolbar,
        EditCardActions,
        DeleteButton,
        DeleteToolbar,
        DeleteCardActions,
        RequiredFields,
        DataTableHeader,
        DataTableSkeletonLoader,
        DataTableFooter,
        ValidationErrors,
        RowsPerPage,
        CopyTooltip,
        Snackbar
    },

    directives: { money : VMoney },

    mixins: [
        editDialog,
        user,
        isRole,
        convertDate,
        paginationWatch,
        currency,
        deleteEntity,
        showSnackbar,
        axiosCatch,
        thousandSeparator,
        convertCase,
        required,
    ],

    data: () => ({
        initialLoad: true,
        loading: false,
        loadingUsers: false,
        loadingItems: false,
        searchingUser: false,
        loadingPaymentMethods: false,
        saving: false,
        deleting: false,
        creatingInvoice: false,
        editDialog: false,
        deleteDialog: false,
        viewDialog: false,
        typing: false,
        billing_information: undefined,
        billing_information_opened: undefined,
        shipping_information_opened: undefined,
        debounce: null,
        itemsDebounce: null,
        apiURI: process.env.VUE_APP_SHOP_BASE_URI,
        route: 'orders',
        entity: 'orders',
        entities: [],
        paymentMethods: [],
        coinsValue: '',
        statuses: ['pending', 'completed'],
        delivery_statuses: ['pending', 'inDelivery', 'delivered'],
        payment_statuses: ['pending', 'refunded', 'paid'],
        validationErrors: [],
        pagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            mustSort: false,
            multiSort: false
        },
        selectedSearchAttributes: ['User'],
        users: [],
        usersPagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 5,
            to: 0,
            total: 0
        },
        userOptions: {},
        userSelectedSearchAttributes: ['Username', 'Name'],
        tab: null,
        categories: [],
        itemsPagination: {
            search: '',
            current_page: 1,
            from: 1,
            last_page: 0,
            path: '',
            per_page: 10,
            to: 0,
            total: 0
        },
        itemOptions: {},
        editedIndex: -1,
        editedEntity: {
            id: '',
            cart: [],
            invoices: [],
            user: {
                id: '',
                username: ''
            },
            payment_method: {
                id: '',
                name: ''
            },
            arena: {
                id: '',
                name: '',
                company: {
                    country: {
                        currency: ''
                    }
                }
            },
            delivery: true,
            delivery_amount: 0,
            status: 'pending',
            payment_status: 'pending',
            delivery_status: 'pending',
            description: '',
            note: '',
            billing_and_shipping_equal: undefined,
            billing_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: '',
                company: {
                    r1: undefined,
                    name: '',
                    pidn: '',
                }
            },
            shipping_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: ''
            },
            referred_from: '',
            serial_number: '',
        },
        defaultEntity: {
            id: '',
            cart: [],
            invoices: [],
            user: {
                id: '',
                username: ''
            },
            payment_method: {
                id: '',
                name: ''
            },
            arena: {
                id: '',
                name: '',
                company: {
                    country: {
                        currency: ''
                    }
                }
            },
            delivery: true,
            delivery_amount: 0,
            status: 'pending',
            payment_status: 'pending',
            delivery_status: 'pending',
            description: '',
            note: '',
            billing_and_shipping_equal: undefined,
            billing_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: '',
                company: {
                    r1: undefined,
                    name: '',
                    pidn: '',
                }
            },
            shipping_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: ''
            },
            referred_from: '',
            serial_number: '',
        },
        toDeleteEntity: {
            id: '',
            cart: [],
            invoices: [],
            user: {
                id: '',
                username: ''
            },
            payment_method: {
                id: '',
                name: ''
            },
            arena: {
                id: '',
                name: '',
                company: {
                    country: {
                        currency: ''
                    }
                }
            },
            delivery: true,
            delivery_amount: 0,
            status: 'pending',
            payment_status: 'pending',
            delivery_status: 'pending',
            description: '',
            note: '',
            billing_and_shipping_equal: true,
            billing_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: '',
                company: {
                    r1: undefined,
                    name: '',
                    pidn: '',
                }
            },
            shipping_information: {
                name: '',
                surname: '',
                email: '',
                address: '',
                phone: '',
                city: '',
                postal_code: '',
                note: ''
            },
            referred_from: '',
            serial_number: '',
        },
        selectedUser: -1,
        selectedItems: [],
        snackbar: {
            show: false,
            color: '',
            message: '',
        }
    }),

    computed: {
        headers() {
            const headers = [
                { text: this.$t('entities.orders.attributes.number'), align: 'left', value: 'number' },
                { text: this.$t('miscellaneous.user'), align: 'left', value: 'user', sortable: false },
                { text: this.$t('generalAttributes.description'), align: 'left', value: 'description', sortable: false },
                { text: this.$t('miscellaneous.paymentMethod'), align: 'left', value: 'payment_method.name', sortable: false },
                { text: this.$t('miscellaneous.gross'), align: 'left', value: 'cart.gross', sortable: false },
                { text: this.$t('entities.orders.attributes.paymentStatus'), align: 'left', value: 'payment_status', sortable: false },
                { text: this.$t('entities.orders.attributes.deliveryStatus'), align: 'left', value: 'delivery_status', sortable: false },
                { text: this.$t('entities.orders.attributes.status'), align: 'left', value: 'status', sortable: false },
                { text: this.$t('miscellaneous.createdAt'), align: 'left', value: 'created_at' },
                { text: this.$t('miscellaneous.actions'), value: 'action', sortable: false }
            ];

            if (!this.$store.getters.arena) {
                headers.splice(0, 0, { text: this.$t('miscellaneous.arena'), align: 'left', value: 'arena.name', sortable: false })
            }

            return headers;
        },
        searchAttributes() {
            return [
                { label: this.$t('entities.orders.attributes.number'), value: 'Number' },
                { label: this.$t('miscellaneous.user'), value: 'User' },
            ]
        },
        userHeaders() {
            return [
                { text: this.$t('entities.users.attributes.username'), align: 'left', value: 'username' },
                { text: this.$t('entities.users.attributes.name'), align: 'left', value: 'identity.name' },
                { text: this.$t('entities.users.attributes.email'), align: 'left', value: 'email' },
            ];
        },
        userSearchAttributes() {
            return [
                { label: this.$t('entities.users.attributes.username'), value: 'Username' },
                { label: this.$t('entities.users.attributes.name'), value: 'Name' },
                { label: this.$t('entities.users.attributes.email'), value: 'Email' },
            ]
        },
        itemHeaders() {
            return [
                { text: this.$t('generalAttributes.name'), align: 'left', value: 'name', sortable: false },
                { text: this.$t('generalAttributes.price'), align: 'left', value: 'price', sortable: false },
                { text: this.$t('generalAttributes.quantity'), align: 'center', value: 'quantity', sortable: false },
            ];
        },
        formTitle() {
            return this.editDialog ? (this.$t(`entities.${this.entity}.${this.editedIndex === -1 ? 'new' : 'edit'}Entity`)) : '';
        },
        money() {
            return {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: false,
                suffix: this.$store.getters.arena ? ` ${this.$store.getters.arena.currency}` : '',
            }
        },
        totalPrice() {
            let total = 0;
            for (const item in this.selectedItems) {
                total += (this.selectedItems[item].quantity * this.selectedItems[item].price);
            }
            return `${this.format(total, true, this.$store.getters.arena.currency)}`;
        },
        getSearchAttributes() {
            let searchAttributes = '';
            for (const i in this.userSelectedSearchAttributes) {
                searchAttributes += this.$i18n.t(`entities.users.attributes.${this.userSelectedSearchAttributes[i].toLowerCase()}`);
                if (parseInt(i) !== this.userSelectedSearchAttributes.length - 1) {
                    searchAttributes += ', ';
                }
            }
            return searchAttributes === '' ? 'results' : searchAttributes;
        },
        arena() {
            return this.$store.getters.arena;
        },
        userSelectedAllAttributes() {
            return this.userSelectedSearchAttributes.length === this.userSearchAttributes.length
        },
        userSelectedSomeAttributes() {
            return this.userSelectedSearchAttributes.length > 0 && !this.userSelectedAllAttributes
        },
        icon() {
            if (this.userSelectedAllAttributes) return 'mdi-close-box'
            if (this.userSelectedSomeAttributes) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        billingAndShippingInformationOpened() {
            return this.billing_information_opened === 0 && this.shipping_information_opened === 0;
        },
        coinsOrPassAddedAndUserNotSelected() {
            const coinsOrPassAdded = this.selectedItems.filter(item => item.category === 'Coins' || item.category === 'Passes').length > 0;
            return coinsOrPassAdded ? coinsOrPassAdded && this.selectedUser === -1 : false;
        },
    },

    watch: {
        coinsValue(val) {
            this.updateCoins(val);
        },
        'itemsPagination.search': function() {
            if (this.itemsDebounce) {
                clearTimeout(this.itemsDebounce)
            }
            this.itemsDebounce = setTimeout(() => {
                this.fetchItems(`&name=${this.itemsPagination.search}`);
            }, 250)
        },
        'usersPagination.per_page': function() {
            if (this.usersPagination.current_page !== 1) {
                this.usersPagination.current_page = 1;
            } else {
                this.fetchUsers(`&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`);
            }
        },

        'usersPagination.search': function() {
            this.searchingUser = true;
            if (this.debounce) {
                clearTimeout(this.debounce)
            }
            this.debounce = setTimeout(() => {
                this.users = [];
                this.usersPagination.current_page = 1;
                this.usersPagination.from = 1;
                this.usersPagination.last_page = 0;
                this.usersPagination.path = '';
                this.usersPagination.per_page = 5;
                this.usersPagination.to = 0;
                this.usersPagination.total = 0;
                if (this.usersPagination.search) {
                    if (this.usersPagination.current_page !== 1) {
                        this.usersPagination.current_page = 1;
                    } else {
                        this.fetchUsers(`&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`);
                    }
                }
                this.searchingUser = false;
            }, 1500);
        },
        'usersPagination.current_page': function() {
            if (this.options.sortBy.length) {
                const sortBy = this.options.sortBy[0];
                const sortDirection = this.options.sortDesc[0] ? 'DESC' : 'ASC';
                this.fetchUsers(`&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}&sort=${sortBy},${sortDirection}`);
            } else {
                this.fetchUsers(`&size=${this.usersPagination.per_page}&${this.formatUserSelectedSearchAttributes()}&page=${this.usersPagination.current_page}`);
            }
        },
        viewDialog(val) {
            if (!val) {
                this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
                this.editedIndex = -1;
                this.billing_information_opened = undefined;
                this.shipping_information_opened = undefined;
            }
        },
        editDialog(val) {
            if (!val) {
                this.billing_information = undefined;
            }
        },
        deleteDialog(val) {
            if (!val) {
                this.billing_information_opened = undefined;
                this.shipping_information_opened = undefined;
            }
        }
    },

    mounted() {
        this.refresh();
    },

    methods: {
        async fetchEntities(append = `&size=${this.pagination.per_page}&page=${this.pagination.current_page}`) {
            try {
                this.loading = true;
                // If not admin and arena not chosen, fetch non-existing arena
                let query = '&arena_id=';
                if (this.isAdmin) {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '';
                } else {
                    query += this.$store.getters.arena ? this.$store.getters.arena.id : '-1';
                }
                const response = await Order.api.get(1, `${query}${append}`);
                this.entities = response.data.data.map(OrderDTO => new Order(OrderDTO));
                response.data.meta.per_page = parseInt(response.data.meta.per_page);
                Object.assign(this.pagination, response.data.meta);
            } catch (e) {
                console.warn('Orders API failed.');
                console.log(e);
            } finally {
                this.initialLoad = false;
                this.loading = false;
            }
        },
        async fetchPaymentMethods() {
            try {
                this.loadingPaymentMethods = true;
                const response = await PaymentMethod.api.get();
                this.paymentMethods = response.data.map(PaymentMethodDTO => new PaymentMethod(PaymentMethodDTO));
            } catch (e) {
                console.warn('Payment Methods API failed.');
                console.log(e);
            } finally {
                this.loadingPaymentMethods = false;
            }
        },
        async fetchUsers(append = '') {
            try {
                this.loadingUsers = true;
                const response = await User.api.get(append);
                const data = response.data;
                this.users = data.content.map(UserDTO => new User(UserDTO));
                this.usersPagination.current_page = data.pageable.page_number + 1;
                this.usersPagination.per_page = data.pageable.page_size;
                this.usersPagination.total = data.total_elements;
                this.usersPagination.last_page = data.total_pages;
                this.usersPagination.from = data.pageable.offset + 1;
                this.usersPagination.to = this.usersPagination.from + data.number_of_elements - 1;
            } catch (e) {
                console.warn('Users API failed.');
                console.log(e);
            } finally {
                this.loadingUsers = false;
                this.searchingUser = false;
            }
        },
        async fetchItems(append = '') {
            if (!this.arena) {
                return;
            }
            try {
                this.loadingItems = true;
                const response = await Item.api.get('/purchase?', `arena_id=${this.arena.id}${append}`);
                this.categories = response.data;
                const obj = Object.values(this.categories)[0];

                if (typeof obj !== 'undefined') {
                    const categoriesLength = obj.length;
                    this.itemsPagination.to = (categoriesLength >= 10 ? 10 : categoriesLength);
                    this.itemsPagination.total = categoriesLength;
                }

                for (const i in this.categories) {
                    // Adding quantity attribute
                    for (const j in this.categories[i]) {
                        this.$set(this.categories[i][j], 'quantity', 0);
                        for (const index in this.selectedItems) {
                            if (this.selectedItems[index].id === this.categories[i][j].id) {
                                this.$set(this.categories[i][j], 'quantity', this.selectedItems[index].quantity);
                            }
                        }
                    }
                }

                if (!this.itemsPagination.search.length && 'Coins' in this.categories) {
                    this.tab = 'Coins';
                }

            } catch (e) {
                console.warn('Variants API failed.');
                console.log(e);
            } finally {
                this.loadingItems = false;
            }
        },
        async save() {
            try {
                if (!this.$refs.form.validate() || !this.selectedItems.length || this.coinsOrPassAddedAndUserNotSelected) {
                    return;
                }

                this.saving = true;

                const data = {
                    arena_id: this.arena.id,
                    variants: this.selectedItems,
                    payment_method: this.editedEntity.payment_method,
                    delivery: this.editedEntity.delivery ? 1 : 0,
                    note: this.editedEntity.note,
                    payment_status: this.editedEntity.payment_status,
                    delivery_status: this.editedEntity.delivery_status,
                    status: this.editedEntity.status,
                };

                if (this.selectedUser !== -1) {
                    data.user = {
                        id: this.selectedUser.id,
                        username: this.selectedUser.username,
                        name: this.selectedUser.identity ? this.selectedUser.identity.name : null,
                        email: this.selectedUser.email
                    }
                }

                if (this.editedEntity.referred_from) {
                    data.referred_from = this.editedEntity.referred_from
                }

                if (this.editedEntity.serial_number) {
                    data.serial_number = this.editedEntity.serial_number
                }

                const billingInformation = JSON.parse(JSON.stringify(this.editedEntity.billing_information));
                billingInformation.company.r1 = billingInformation.company.r1 === 0 ? 1 : undefined;
                data.billing_information = billingInformation;

                let shippingInformation;
                if (this.editedEntity.billing_and_shipping_equal === 0) {
                    shippingInformation = this.editedEntity.shipping_information;
                } else {
                    shippingInformation = JSON.parse(JSON.stringify(this.editedEntity.billing_information));
                    delete shippingInformation.company;
                }

                data.shipping_information = shippingInformation;

                if (this.editedIndex > -1) {
                    await Order.api.update(this.editedEntity.id, data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.resetEditDialogData();
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.orders.notifications.update'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                } else {
                    await Order.api.create(data)
                        .then(() => {
                            if (this.pagination.search.length) {
                                this.pagination.search = '';
                            } else {
                                this.fetchEntities();
                            }
                            this.resetEditDialogData();
                            this.closeEditDialog();
                            this.showSnackbar('success', this.$t('entities.orders.notifications.creation'));
                        })
                        .catch(error => {
                            this.axiosCatch(error);
                        });
                }
            } catch (error) {
                console.warn(`Order Save API failed.`);
                console.log(error);
            } finally {
                this.saving = false;
            }
        },
        async createInvoice() {
            try {
                this.creatingInvoice = true;

                const byUser = {
                    id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                    username: this.user.username,
                    pidn: this.user.pidn
                };

                await Order.api.createInvoice(this.editedEntity.id, { by_user: byUser })
                    .then(() => {
                        this.editedIndex = -1;
                        this.viewDialog = false;
                        this.fetchEntities();
                        this.showSnackbar('success', this.$t('entities.orders.notifications.invoice'));
                    }).catch(error => {
                        let message = error.response.data.message;
                        const errors = [];
                        let iconAndColor = 'error';

                        // Validation errors
                        if (error.response.status === 422) {
                            iconAndColor = 'warning';
                            for (const i in error.response.data.errors) {
                                errors.push(`${i}:${error.response.data.errors[i]}`)
                            }
                            message += ' The following rules failed: ' + errors.join(', ');
                        }

                        // Unexpected errors
                        if (error.response.status === 500) {
                            if (typeof error.response.data.errors !== 'undefined' ) {
                                for (const i in error.response.data.errors) {
                                    errors.push(error.response.data.errors[i])
                                }
                                message += ' The following errors occurred: ' + errors.join(', ');
                            }
                        }

                        this.editedIndex = -1;
                        this.viewDialog = false;
                        this.fetchEntities();

                        this.showSnackbar(iconAndColor, message, 10000, iconAndColor);
                    });
            } catch (error) {
                console.warn(`Create Invoice API failed.`);
                console.log(error);
            } finally {
                this.creatingInvoice = false;
            }
        },
        rowClick: function (item, row) {
            row.select(true);
            this.selectedUser = item;
        },
        orderStatusColor(status) {
            if (status === 'pending') return 'blue darken-2';
            else if (status === 'inDelivery' ) return 'yellow darken-4';
            else if (status === 'refunded' ) return 'red darken-2';
            else if (status === 'completed' || status === 'paid' || status === 'delivered') return 'green darken-2';
        },
        openViewDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = {...entity};
            this.viewDialog = true;
        },
        openEditDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.editedEntity = JSON.parse(JSON.stringify(entity));
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
            if (!this.editedEntity.billing_information) {
                this.editedEntity.billing_information = JSON.parse(JSON.stringify(this.defaultEntity.billing_information));
            }
            if (!this.editedEntity.shipping_information) {
                this.editedEntity.shipping_information = JSON.parse(JSON.stringify(this.defaultEntity.shipping_information));
            }
            if (this.editedEntity.billing_information.company.r1 !== undefined) {
                this.editedEntity.billing_information.company.r1 = 0;
            }

            const defaultBillingInformation = JSON.parse(JSON.stringify(this.defaultEntity.billing_information));
            delete defaultBillingInformation.company;
            const currentBillingInformation = JSON.parse(JSON.stringify(this.editedEntity.billing_information));
            delete currentBillingInformation.company;

            if (JSON.stringify(defaultBillingInformation) !== JSON.stringify(currentBillingInformation)) {
                this.billing_information = 0;
            }

            if (this.editedIndex > -1) {
                for (const i in this.editedEntity.cart.items) {
                    for (const j in this.categories) {
                        for (const k in this.categories[j]) {
                            if (this.editedEntity.cart.items[i].variant_id === this.categories[j][k].id) {
                                if (this.categories[j][k].name === 'Coins') {
                                    this.coinsValue = this.format(this.editedEntity.cart.items[i].total_price, true, this.editedEntity.arena.company.country.currency);
                                } else {
                                    this.$set(this.categories[j][k], 'quantity', this.editedEntity.cart.items[i].quantity);
                                    this.selectedItems.push(this.categories[j][k]);
                                }
                            }
                        }
                    }
                }
                if (!this.editedEntity.billing_and_shipping_equal) {
                    this.editedEntity.billing_and_shipping_equal = 0;
                }

                if (this.editedEntity.user) {
                    this.selectedUser = this.editedEntity.user;
                }
            }
            this.editDialog = true;
        },
        closeEditDialog() {
            this.editedEntity = JSON.parse(JSON.stringify(this.defaultEntity));
            this.editedIndex = -1;
            this.resetEditDialogData();
            this.editDialog = false;
        },
        openDeleteDialog(entity) {
            this.editedIndex = this.entities.indexOf(entity);
            this.toDeleteEntity = {...entity};
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
            this.toDeleteEntity = {...this.defaultEntity};
            this.editedIndex = -1;
        },
        resetCoinsItem() {
            this.$set(this, 'coinsValue', '');
            if (this.$refs.coins && this.$refs.coins[0] && this.$refs.coins[0].$el) {
                this.$refs.coins[0].$el.getElementsByTagName('input')[0].value = '';
            }
        },
        resetEditDialogData() {
            this.users = [];
            this.selectedUser = -1;
            this.usersPagination = {
                search: '',
                current_page: 1,
                from: 1,
                last_page: 0,
                path: '',
                per_page: 5,
                to: 0,
                total: 0
            };
            this.resetCoinsItem();
            this.selectedItems = [];
            for (const i in this.categories) {
                for (const j in this.categories[i]) {
                    this.$set(this.categories[i][j], 'quantity', 0);
                }
            }
        },
        updateCoins(price) {
            const amount = this.unFormat(price, 0) / 100;
            const coinsItem = this.categories['Coins'].find(item => {
                return item.name === 'Coins';
            });
            const coinsPerCurrency = coinsItem.coins_per_currency;

            coinsItem.quantity = Math.round(amount * coinsPerCurrency);

            let exists = false;
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === coinsItem.item_id) {
                    exists = true;
                    // If in cart and quantity less than 1, remove. Else, update quantity
                    if (coinsItem.quantity < 1 ) {
                        this.selectedItems.splice(index, 1);
                    } else {
                        this.selectedItems[index] = coinsItem;
                    }
                }
            }

            // If not in cart, add it
            if (!exists && coinsItem.quantity > 0) {
                this.selectedItems.unshift(coinsItem);
            }
        },
        incrementQuantity(item) {
            item.quantity++;
            this.updateCart(item);
        },
        decrementQuantity(item) {
            if (item.quantity !== 0) {
                item.quantity--;
                if (item.quantity === 0) {
                    for (const index in this.selectedItems) {
                        if (this.selectedItems[index].id === item.id) {
                            this.selectedItems.splice(index, 1);
                        }
                    }
                }
            }
        },
        removeItem(item) {
            if (item.name === 'Coins') {
                this.resetCoinsItem();
            }
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === item.id) {
                    item.quantity = 0;
                    this.selectedItems.splice(index, 1);
                }
            }
        },
        handleChange(item, event) {
            const qty = parseInt(event.target.value);
            if (!Number.isInteger(qty)) {
                this.removeItem(item);
                return;
            }
            if (qty >= 0) {
                item.quantity = qty;
                this.updateCart(item);
            }
        },
        updateCart(item) {
            let exists = false;
            for (const index in this.selectedItems) {
                if (this.selectedItems[index].id === item.id) {
                    exists = true;
                }
            }
            if (!exists && item.quantity > 0) {
                this.selectedItems.push(item);
            }
        },
        toggle() {
            this.$nextTick(() => {
                if (this.userSelectedAllAttributes) {
                    this.userSelectedSearchAttributes = [];
                } else {
                    this.userSelectedSearchAttributes = this.userSearchAttributes.slice();
                }
            })
        },
        formatUserSelectedSearchAttributes() {
            if (!this.usersPagination.search.length) {
                return '';
            }

            const searchAttributes = this.userSelectedSearchAttributes.map(attribute => `${attribute.toLowerCase()}=${this.usersPagination.search}`);
            return '&' + searchAttributes.join('&');
        },
        refresh() {
            this.fetchItems();
            this.fetchEntities();
            this.fetchPaymentMethods();
        }
    }
});

</script>

<style lang="scss">

.orders-users-table {
    margin-top: -25px;
    border-collapse: separate;
    .v-data-table__empty-wrapper td {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    tr:not(.v-data-table__empty-wrapper) {
        cursor: pointer;
        td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
    .active-checkmark {
        position: absolute !important;
        bottom: 7px;
        left: 5px;
    }
}

</style>
